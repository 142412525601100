import { applyMiddleware, createStore } from "redux";
import { configureStore } from '@reduxjs/toolkit';
import thunk from "redux-thunk";
import allReducers from "./reducers/index";

const preLoadedState = [ {generator : { id: 1 , name : "first Genrator", collections : []}, amount : 200}] ;
const middleware = [thunk];

const store = configureStore({
    reducer : allReducers,
    preLoadedState,
    middleware
  })
  
export default store;