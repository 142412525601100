import React from "react";
import { useSelector } from "react-redux";
import { Image } from "antd";

export default function DrawerPreviewComponent(props) {
  const data = props.data;
  const artData = props.artData;
  const drawerPreviewData = useSelector(state => state.drawerPreview.data);
  
  return (
    <div className="drawerPreview">  
     { drawerPreviewData && drawerPreviewData.imageData && (      
      <div>
        <div className="heading"><span>Name : {drawerPreviewData.imageData.name}</span> <span>Total images :{drawerPreviewData.blendedData.count}</span></div>
        <div className="image-orginal" >         
        <Image src={drawerPreviewData.imageData.input_url} />
        </div>
        <div className="blended-data">
        { drawerPreviewData && drawerPreviewData.blendedData && (
          <div>
          <div className="result-item">
          { drawerPreviewData.blendedData.results.map( (result) => {
            return (
              <div className="item  text-truncate" >
                <label className="text-truncate"><span>{result.blending_image_name}</span></label>
              <Image src={(result.blending_image_url? result.blending_image_url  : result.blending_image_url)} />
              </div>
            )
          }) }             
          </div>
          </div>
        )}
        </div>
      </div>
      )
     }
      </div>
    )
  }