import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from 'react-router-dom';
import Header from "../components/Header";
import Sidenav from "../components/Sidenav";

const ProtectedRoute = ({modelTypesList}) => {    
    
    const auth = localStorage.getItem("processingToken") ? true : false;// determine if authorized, from context or however you're doing it    
    
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth ? <><Sidenav modelTypesList={modelTypesList} /><Header className="header-page-ig" /><Outlet /></> : <Navigate to="/login" />;
}

export default ProtectedRoute;