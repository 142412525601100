import Header from "../components/Header";
import Sidenav from "../components/Sidenav";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import GeneratorModal from "../components/modals/CreateGeneratorModal";
import ConfirmationModel from "../components/modals/ConfirmationModal";
import CollectionListModel from "../components/modals/CollectionListModal";
import { RiDeleteBin6Line, RiEyeFill } from "react-icons/ri";
import { BiEdit, BiListUl } from "react-icons/bi";
import { MdOutlineNightlightRound } from "react-icons/md";
import { FiSunrise, FiCloudOff } from "react-icons/fi";
import { GiWinterHat, GiSewingNeedle, GiThreeLeaves } from "react-icons/gi";
import { BsDiagram3, BsCloudRainHeavy } from "react-icons/bs";

import { RiTempHotFill, RiDragDropFill } from "react-icons/ri";
import { FaPaintBrush } from "react-icons/fa";
import { Badge } from "antd";
import useUpdateCollectionDrawer from "../hook/useUpdateCollectionDrawer";
import { useBetween } from "use-between";
import SharedComponent from "../components/SharedComponent";
import LoadingSpinner from "../components/LoadingSpinner";

import useGeneratorState from "../hook/useGeneratorState";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { generatorActionCreator } from "./../state/index";
import amountActionCreators from "../state/actions/amountActions";
function Home(props) {
  const listGenerator = useSelector((state) => state.generator);
  const amountState = useSelector((state) => state.amount);

  const navigation = useNavigate();
  const [showGeneratorModal, setShowGeneratorModal] = useState(false);
  const {
    creatNewGenerator,
    removeGenerator,
    titleGenerator,
    generatorDataLoading,
  } = useGeneratorState();
  const { errorMessageGen, setErrorMessageGen } = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedGenerator, setSelectedGenerator] = useState(null);
  const [showCollectionListModal, setShowCollectionListModal] = useState(false);
  const [selectedCollectionList, setSellectionCollectionList] = useState([]);
  const {
    showDrawer,
    setShowDrawer,
    toggleDrawer,
    setStateObj,
    setCollectionFieldName,
  } = useBetween(useUpdateCollectionDrawer);
  const [generatorDataCounts, setGeneratorDataCounts] = useState({
    generator: 0,
    collection: 0,
    images: 0,
  });
  const openCollectionListModal = (generator) => {
    setSellectionCollectionList(generator);
    setShowCollectionListModal(true);
  };

  const redirectToAddCollection = (data) => {
    updateGeneratorCollection(data);
  };

  const deleteGeneratorProceed = (name, id, index) => {
    setShowConfirmationDialog(false);
    removeGenerator(selectedGenerator.id, index, (res) => {});
  };

  const deleteGenerator = (generator, key) => {
    setSelectedGenerator(generator);
    setShowConfirmationDialog(true);
  };

  const createGenerator = (data) => {
    creatNewGenerator(data, redirectToAddCollection);
    setShowGeneratorModal(false);
  };

  const updateGeneratorCollection = (generator) => {
    if (generator.collections && generator.collections.length > 0) {
      setCollectionFieldName(generator.collections[0].name);
    } else {
      setCollectionFieldName("");
    }

    setStateObj(generator);
    toggleDrawer(true);
    setShowDrawer(true);
  };

  const findCollectionFromGenerator = (collections, type) => {
    return collections.findIndex((item) => item.model_type == type);
  };
  const navigatetoviewScreenshots = (generatorData, title) => {
    const collectionIndex = findCollectionFromGenerator(
      generatorData.collections,
      title
    );
    navigation("/collection-preview-page", {
      state: {
        id: generatorData.id,
        name: generatorData.name,
        type: title,
        collection: generatorData.collections[collectionIndex],
      },
    });
  };
  useEffect(
    (app) => {
      let contGenerator = 0;
      let contCollections = 0;
      let contTotalImage = 0;
      setGeneratorDataCounts({ generator: 0, collections: 0, images_count: 0 });
      listGenerator.forEach((element) => {
        contGenerator++;
        contTotalImage += element.images_count;
        contCollections +=
          element.collections && element.collections.length > 0
            ? element.collections.length
            : 0;
      });
      setGeneratorDataCounts({
        generator: contGenerator,
        collection: contCollections,
        images: contTotalImage,
      });
    },
    [listGenerator]
  );
  return (
    <>
      {/* <Sidenav modelTypesList={props.modelTypesList} /> */}
      <main className="main-content">
        <SharedComponent
          title="test"
          modelTypesList={props.modelTypesList}
        ></SharedComponent>
        {/* <Header /> */}
        <div className="scrolldiv">
          {showConfirmationDialog && (
            <ConfirmationModel
              type="alert"
              content="Do you want to delete this generator"
              showModal={setShowConfirmationDialog}
              onSubmit={deleteGeneratorProceed}
            />
          )}

          {showCollectionListModal && (
            <CollectionListModel
              title="Collection List"
              type="alert"
              list={selectedCollectionList}
              showModal={setShowCollectionListModal}
            />
          )}

          <div className="generators">
            <div className="title-bar">
              <h2>
                <div className="heading">{titleGenerator}</div>
                <div className="data-counts">
                  <div className="item">
                    <label>Generators</label>
                    <div className="round generator">
                      {generatorDataCounts.generator}
                    </div>
                  </div>
                  <div className="item">
                    <label>Collections</label>
                    <div className="round collection">
                      {generatorDataCounts.collection}
                    </div>
                  </div>
                  <div className="item">
                    <label>Images</label>
                    <div className="round images">
                      {generatorDataCounts.images}
                    </div>{" "}
                  </div>
                </div>
              </h2>
              {/* <Link to="/generate" className="btn-theme"> */}
              {/* </Link> */}
              <button
                className="btn-theme"
                onClick={() => setShowGeneratorModal(true)}
              >
                Create Generator
              </button>

              {/* <div className="btn-theme"> Create New</div> */}
            </div>
            {/* {generatorDataLoading && (
              <div className="setLoadinData">
                {" "}
                <LoadingSpinner />
              </div>
            )}{" "} */}
            {showGeneratorModal && (
              <GeneratorModal
                errorMessage={errorMessageGen}
                type="info icontop"
                createGenerator={createGenerator}
                setIsOpen={setShowGeneratorModal}
              />
            )}
            <div className="row">
              {listGenerator.map((generator, key) => (
                <div
                  className="col-xxl-3 col-xl-4 col-lg-4 col-md-4  col-sm-6"
                  key={key}
                >
                  <div className="generator-item">
                    <div className="generator-item-header">
                      {/* {generator.model_types_gen.length > 0 &&
                        generator.model_types_gen.indexOf("EPICYCLES") > -1 && (
                          <Badge.Ribbon text="Epicycles" status="success" />
                        )} */}

                      <h3 className="text-truncate">
                        <Link
                          to="/collection-preview-page"
                          state={{ id: generator.id, name: generator.name }}
                          className="link-white"
                        >
                          {generator.name}
                        </Link>
                        {/* <span>  ({generator.author ? generator.author : 'Draw By Epicycles'} )</span> */}
                      </h3>
                    </div>
                    <div className="generator-item-body">
                      <table>
                        {/* <thead>
                        <tr>
                          <th>Images</th>
                          <th>Collections</th>
                          <th>Status</th> 
                        </tr>
                      </thead> */}
                        <tbody>
                          <tr>
                            <td>
                              <div className="generator-count-bar">
                                <div>Image</div>
                                <div className="count">
                                  {generator.images_count
                                    ? generator.images_count
                                    : "0"}
                                </div>
                              </div>
                            </td>
                            <td className="collection-list">
                              <div className="generator-count-bar-parent">
                                <div className="generator-count-bar generator-count-bar-right">
                                  <div>Collection</div>
                                  <div className="count">
                                    {generator.collections.length
                                      ? generator.collections.length
                                      : "0"}
                                  </div>
                                </div>
                                {generator.collections.length > 0 && (
                                  <div
                                    className="list"
                                    onClick={() => {
                                      openCollectionListModal(generator);
                                    }}
                                  >
                                    <BiListUl className="list-icon" />
                                  </div>
                                )}
                              </div>
                            </td>
                            {/* <td>{generator.input ? generator.status : 'Processing'}</td> */}
                          </tr>
                        </tbody>
                      </table>
                      <div className="botton-button-bar">
                        {generator.model_types_gen.length > 0 && (
                          <div className="generator-icons-models-heading">
                            <div>
                              <div className="heading">Models</div>
                            </div>
                            <div className="icons-model generator-icons-models">
                              {generator.model_types_gen.map((item, i) => {
                                if (item === "DAY2NIGHT") {
                                  return (
                                    <div
                                      key={i}
                                      title={item}
                                      className="icon DAY2NIGHT"
                                      onClick={() => {
                                        navigatetoviewScreenshots(
                                          generator,
                                          item
                                        );
                                      }}
                                    >
                                      <MdOutlineNightlightRound />
                                    </div>
                                  );
                                }
                                if (item === "NIGHT2DAY") {
                                  return (
                                    <div
                                      title={item}
                                      className="icon NIGHT2DAY"
                                      onClick={() => {
                                        navigatetoviewScreenshots(
                                          generator,
                                          item
                                        );
                                      }}
                                    >
                                      <FiSunrise />
                                    </div>
                                  );
                                }
                                if (item === "SUMMER2WINTER") {
                                  return (
                                    <div
                                      title={item}
                                      className="icon"
                                      onClick={() => {
                                        navigatetoviewScreenshots(
                                          generator,
                                          item
                                        );
                                      }}
                                    >
                                      <GiWinterHat />
                                    </div>
                                  );
                                }
                                if (item === "WINTER2SUMMER") {
                                  return (
                                    <div
                                      title={item}
                                      className="icon"
                                      onClick={() => {
                                        navigatetoviewScreenshots(
                                          generator,
                                          item
                                        );
                                      }}
                                    >
                                      <GiThreeLeaves />
                                    </div>
                                  );
                                }
                                if (item === "EPICYCLES") {
                                  return (
                                    <div
                                      title={item}
                                      className="icon"
                                      onClick={() => {
                                        navigatetoviewScreenshots(
                                          generator,
                                          item
                                        );
                                      }}
                                    >
                                      <GiSewingNeedle />
                                    </div>
                                  );
                                }
                                if (item === "BLENDING") {
                                  return (
                                    <div
                                      title={item}
                                      className="icon"
                                      onClick={() => {
                                        navigatetoviewScreenshots(
                                          generator,
                                          item
                                        );
                                      }}
                                    >
                                      <RiDragDropFill />
                                    </div>
                                  );
                                }
                                if (item === "INPAINTING") {
                                  return (
                                    <div
                                      title={item}
                                      className="icon"
                                      onClick={() => {
                                        navigatetoviewScreenshots(
                                          generator,
                                          item
                                        );
                                      }}
                                    >
                                      <FaPaintBrush />
                                    </div>
                                  );
                                }
                                if (item === "SEGMENTATION") {
                                  return (
                                    <div
                                      title={item}
                                      className="icon"
                                      onClick={() => {
                                        navigatetoviewScreenshots(
                                          generator,
                                          item
                                        );
                                      }}
                                    >
                                      <BsDiagram3 />
                                    </div>
                                  );
                                }
                                if (item === "CLEAR2RAIN") {
                                  return (
                                    <div
                                      title={item}
                                      className="icon"
                                      onClick={() => {
                                        navigatetoviewScreenshots(
                                          generator,
                                          item
                                        );
                                      }}
                                    >
                                      <BsCloudRainHeavy />
                                    </div>
                                  );
                                }
                                if (item === "RAIN2CLEAR") {
                                  return (
                                    <div
                                      title={item}
                                      className="icon"
                                      onClick={() => {
                                        navigatetoviewScreenshots(
                                          generator,
                                          item
                                        );
                                      }}
                                    >
                                      <FiCloudOff />
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        )}

                        {(!generator.model_types_gen ||
                          generator.model_types_gen.length) == 0 && (
                          <div className="generator-icons-models-heading no-bg"></div>
                        )}

                        <div className="btn-group">
                          <button className="btn btn-icon blue-icons">
                            <Link
                              to="/collection-preview-page"
                              state={{ id: generator.id, name: generator.name }}
                              className="btn-eye"
                            >
                              <RiEyeFill />
                            </Link>
                          </button>
                          <button
                            className="btn btn-icon btn-edit  blue-icons"
                            onClick={() => {
                              updateGeneratorCollection(generator);
                            }}
                          >
                            <BiEdit />
                          </button>
                          {/* <button className="btn btn-icon btn-edit  blue-icons">
                            <Link
                              to="/uploader"
                              state={generator}
                              params={{ id: "434534" }}
                            >
                              <BiEdit />
                            </Link>
                          </button> */}
                          <button
                            className="btn btn-icon btn-delete"
                            onClick={() => {
                              deleteGenerator(generator, key);
                            }}
                          >
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Home;
