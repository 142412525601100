const loadDrawerData = (drawerData) => {
    return (dispatch) => {
        dispatch( {
            type : 'loadDrawerData',
            payload: drawerData
        })
    }
}
const selectedBlendedImage = (selected) => {
    return (dispatch) => {
        dispatch( {
            type : 'selectedBlendedImage',
            payload: selected
        })
    }
}

export default { loadDrawerData, selectedBlendedImage};