const collectionReducers = ( state = { reload: false,
    model_types: [],
    model_types_names: []
}, action) => {      
    if( action.type === 'reloadCollection') {          
        state = { ...state, reload :action.payload};        
        return state;
    } else if( action.type === 'resetModelTypes') {          
        state = { ...state, model_types: action.payload};        
        return state;    
    } else if( action.type === 'resetModelTypesNames') {          
        state = { ...state, model_types_names: action.payload};        
        return state;
    }  else {        
        return state;
    }
}

export default collectionReducers ;