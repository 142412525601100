const toggleModifyCollectionDrawer = (settings) => {
  return (dispatch) => {
      dispatch( {
          type : 'toggleModifyCollectionDrawer',
          payload: settings
      })
  }
}
const setCollectionDrawerObject = (obj) => {
  return (dispatch) => {
      dispatch( {
          type : 'setCollectionDrawerObject',
          payload: obj
      })
  }
}
// const selectedBlendedImage = (settings) => {
//   return (dispatch) => {
//       dispatch( {
//           type : 'selectedBlendedImage',
//           payload: settings
//       })
//   }
// }

export default { toggleModifyCollectionDrawer, setCollectionDrawerObject};