import "./assets/css/grid.min.css";
import "antd/dist/antd.min.css";
import "./style.css";
import "./assets/css/theme2.scss";
import Routes from "./Routes/Routes";

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
