const settingsReducers = ( state = {
    toggleCollectionDrawer : false ,
    toggleBlendingTool : false,
    drawerState : {}
}, action) => {          
  if( action.type === 'toggleModifyCollectionDrawer') {                  
      state = { ...state , toggleCollectionDrawer : action.payload};          
      return state;
  } else if( action.type === 'toggleBlendingTool') {            
      state.toggleBlendingTool = action.payload;   
      return state;  
  } else if( action.type === 'setCollectionDrawerObject') {            
      state = {  ...state , drawerState :  action.payload }; 
      console.log(state);
      return state;
  } else {        
      return state;
  }
}

export default settingsReducers ;