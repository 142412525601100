const drawerDataReducers = ( state = {data : {} , selected : 0}, action) => {      
    if( action.type === 'loadDrawerData') {            
        state.data = action.payload;        
        state = { data : action.payload , selected : action.payload.imageData.id};        
        return state;
    } else if( action.type === 'selectedBlendedImage') {            
        state = [state.selected, action.payload];        
        return state;
    } else {        
        return state;
    }
}

export default drawerDataReducers ;