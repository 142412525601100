import { useState, useEffect } from "react";
import API_PATH from "../config/config";
import axios from "axios";
import authToken from "./useAuthToken";

const useMediaGalleryState = () => {

    const [mediaModalVisibility , setMediaModelVisibility] = useState(false);
    const [callBackParenContainer, setCallBackParenContainer] = useState(() => {});
    const [showMediaPicker , setShowMediaPicker] = useState(false);
    const [selectedMedia, setSelectedMedia ] = useState();
    
    const displayMediaModel = (callback) => {
      setMediaModelVisibility(true);
      setCallBackParenContainer((callBackParenContainer) => [callBackParenContainer, callback ]);      
    }

    const triggerCallBack = (obj) => {      
      if( callBackParenContainer != undefined && callBackParenContainer.length > 0 ) {
        callBackParenContainer[1](obj);
        setMediaModelVisibility(true);
        setMediaModelVisibility(false);
      }
    }

    useEffect(() => {      
      setShowMediaPicker(mediaModalVisibility);
    }, [mediaModalVisibility]);
  
  return { displayMediaModel, triggerCallBack, mediaModalVisibility, setMediaModelVisibility, setSelectedMedia, selectedMedia, showMediaPicker};
};

export default useMediaGalleryState;
