import React from 'react';
import axios from "axios";
import { ReactP5Wrapper } from "react-p5-wrapper";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Spin } from "antd";
import ImageProcessComponent from "./../components/ImageProcessingTypeOne";
import  {sketch, drawSketch, handleP5Events} from "./../lib/p5js.js";
// import  {sketch, drawSketch} from "./../lib/p5js-o";


var t = 0;
var tail = [];
var a;
var f1;
var f2;
var pGlobal;
var isCreateArt = false;


export default function ImageProcessingTypeOne(props) {
    const data = props.data;        
    const artData = props.artData;
    if(data){        
        drawSketch(data);
              isCreateArt = true;
    }
  return (
    <>        
                      {artData && (
                        <div className="col-md-5">
                          <div className="input-images">
                            <div className="input-container">
                              <div className="input-images-title">
                                Orignal Image 3
                              </div>
                              <div className="image-container original-img">
                                <TransformWrapper>
                                  <TransformComponent>
                                    <img src={artData.original_image} alt="" />
                                  </TransformComponent>
                                </TransformWrapper>
                              </div>
                            </div>
                          </div>
                          <div className="input-images">
                            <div className="input-container">
                              <div className="input-images-title">
                                Edge Image
                              </div>
                              <div className="image-container">
                                <TransformWrapper>
                                  <TransformComponent>
                                    <img src={artData.edge_image} alt="" />
                                  </TransformComponent>
                                </TransformWrapper>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-md-7">
                        {/* <div className="btn-process-container">
                        <button className="btn-stop-process" onClick={() =>{
                          handleP5Events("stop");
                        }}> Stop </button>
                        <button className="btn-stop-process" onClick={() =>{
                          handleP5Events("resume");
                        }}> Resume </button>
                        </div> */}
                        
                        <div className="sketch">
                          <ReactP5Wrapper sketch={sketch} />
                        </div>
                      </div>
    </>
  );
}
