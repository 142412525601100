const reloadCollection = (reload) => {
    return (dispatch) => {
        dispatch( {
            type : 'reloadCollection',
            payload: reload
        })
    }
}
const resetModelTypes = (reload) => {
    return (dispatch) => {
        dispatch( {
            type : 'resetModelTypes',
            payload: reload
        })
    }
}

const resetModelTypesNames = (reload) => {
    return (dispatch) => {
        dispatch( {
            type : 'resetModelTypesNames',
            payload: reload
        })
    }
}

export default { reloadCollection, resetModelTypes, resetModelTypesNames};