const reducerGenerator = ( state = [], action) => {
    if( action.type === 'addnew') {   
        return state = [ ...state, action.payload[0] ];
    } else if( action.type === 'removeGenerator') {        
        return state.filter(item => item.id !== action.payload);               
    } 
    else if( action.type === 'load') {
        return (state = action.payload) ;
    } else {
        return state;
    }
}

export default reducerGenerator ;