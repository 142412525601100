import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import authToken from "../hook/useAuthToken";
import API_PATH from "./../config/config";
import useGeneratorState from "../hook/useGeneratorState";
import { useSelector, useDispatch } from "react-redux";

const Sidenav = (props) => {
  const mediaModelReduc = useSelector((state) =>
    state.modeltypes && state.modelsList.value
      ? state.modelsList.value
      : { model_types: [], model_types_names: [] }
  );
  const dispatch = useDispatch();
  const { setModelType, resetGeneratorHeading } = useGeneratorState();
  const navigate = useNavigate();

  const { appToken, resetToken, modelTypes, modelTypesName } = authToken();
  const [selectedNavigation, setSelectedNavigation] = useState("");

  const [modelTypesList, setModelTypesList] = useState(null);

  const getModelNames = (type, i) => {
    if (modelTypesName && modelTypesName.length > 0) {
      if (modelTypesName[i] && modelTypesName[i] != "") {
        return modelTypesName[i];
      } else {
        return type;
      }
    }
  };

  useEffect(() => {
    if (
      window.location.search != "" &&
      window.location.search.indexOf("?name=") > -1
    ) {
      setSelectedNavigation(window.location.search.split("?name=")[1]);
    } else {
      setSelectedNavigation(selectedNavigation);
    }
  }, [window.location.search]);

  useEffect(() => {}, [props.modelTypesList]);
  useEffect(() => {
    if (props.selectedModelType) {
      setSelectedNavigation(props.selectedModelType);
    }
  }, [props.selectedModelType]);
  const logout = () => {
    const url = `${API_PATH.USER_MANAGEMENT}/logout/`;
    const res = fetch(url, {
      method: "get",
      headers: {
        Authorization: `Token ${appToken}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        localStorage.removeItem("processingToken");
        resetToken(null);
        window.location = "/login";
      })
      .catch((error) => {
        localStorage.removeItem("processingToken");
        resetToken(null);
        window.location = "/login";
      });
    localStorage.removeItem("processingToken");
    resetToken(null);
    window.location = "/login";
  };

  return (
    <div className="sidnav">
      <Link to="/" className="site-logo">
        <svg
          width="38"
          height="40"
          viewBox="0 0 38 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35.4946 30.5635C35.1409 30.4886 34.8072 30.7381 34.7358 31.1316C34.0182 35.1335 31.2345 37.8225 27.8104 37.8225C24.5773 37.8225 21.7364 35.4222 20.7706 32.0037H23.7922V30.549H18.6479V36.3678H19.934V33.5689C21.3287 36.9737 24.3805 39.2772 27.8104 39.2772C31.8641 39.2772 35.1538 36.1198 35.9968 31.4211C36.0675 31.0268 35.8425 30.6435 35.4946 30.5635Z"
            fill="#333333"
          />
          <path
            d="M35.3662 25.6815C33.9194 22.4426 31.0553 20.366 27.81 20.366C23.8817 20.366 20.3888 23.2732 19.3163 27.4366C19.2172 27.8221 19.4127 28.2258 19.7542 28.3378C20.0943 28.4498 20.4518 28.2287 20.5509 27.8425C21.4498 24.3533 24.5023 21.8207 27.81 21.8207C30.7454 21.8207 33.3156 23.8326 34.4524 26.9122H31.508V28.3669H36.6522V22.548H35.3662V25.6815Z"
            fill="#333333"
          />
          <path
            d="M36.6525 0H0.643026C0.288076 0 0 0.325129 0 0.727357V32.7311C0 33.1333 0.288076 33.4584 0.643026 33.4584H17.8832C17.5488 32.3128 17.3617 31.0923 17.3617 29.8216C17.3617 26.3201 18.7423 23.1823 20.9131 21.0468L15.4159 14.8294L4.92622 25.2764C4.80405 25.3979 4.65229 25.4575 4.50118 25.4575C4.32306 25.4575 4.14559 25.3746 4.01827 25.2109C3.78356 24.9091 3.80929 24.4494 4.07614 24.1839L15.0185 13.2866C15.2731 13.0335 15.6577 13.0473 15.8982 13.3186L18.948 16.7685L25.2458 8.96613C25.3609 8.82357 25.5217 8.73847 25.6921 8.72974C25.8619 8.72538 26.029 8.79011 26.1551 8.92031L33.8714 16.9212C34.1331 17.1925 34.1511 17.653 33.9113 17.949C33.6714 18.2443 33.2644 18.2654 33.0027 17.9941L25.7615 10.4841L19.8579 17.797L21.9375 20.1492C23.5733 18.9098 25.5378 18.1839 27.6501 18.1839C30.7257 18.1839 33.483 19.3913 35.3664 21.8207H37.2955V0.727357C37.2955 0.325129 37.0074 0 36.6525 0ZM10.2884 12.4654C8.31368 12.4654 6.7074 10.6485 6.7074 8.41552C6.7074 6.18108 8.31368 4.36414 10.2884 4.36414C12.2631 4.36414 13.8694 6.18108 13.8694 8.41479C13.8694 10.6485 12.2631 12.4654 10.2884 12.4654Z"
            fill="#333333"
          />
        </svg>
      </Link>

      <Link
        to="/"
        className={`menu-item ${selectedNavigation == "" ? " selected" : ""}`}
      >
        <div>All Generators</div>
      </Link>

      {props.modelTypesList != null &&
        props.modelTypesList.length > 0 != undefined &&
        props.modelTypesList.map((modelType, index) => {
          return (
            <>
              {modelType != "TEXT2IMAGE" && modelType != "ID2MESH" && (
                <Link
                  key={index}
                  to={"/model?name=" + modelType}
                  params={{ name: modelType }}
                  className={`menu-item  ${
                    selectedNavigation == modelType ? " selected" : ""
                  }`}
                  onClick={() => {
                    setModelType(modelType);
                  }}
                >
                  <div>{modelType}</div>
                </Link>
              )}
              {modelType === "TEXT2IMAGE" && (
                <Link
                  to="/text-to-image?name=TEXT2IMAGE"
                  className={`menu-item  ${
                    selectedNavigation == modelType ? " selected" : ""
                  }`}
                >
                  <div>Text To Image</div>
                </Link>
              )}
              {modelType === "ID2MESH" && (
                <Link
                  to="/mesh?name=ID2MESH"
                  className={`menu-item  ${
                    selectedNavigation == modelType ? " selected" : ""
                  }`}
                >
                  <div>3D Mesh</div>
                </Link>
              )}
            </>
          );
        })}

      <a
        className="menu-item"
        onClick={() => {
          logout();
        }}
      >
        Logout
      </a>
    </div>
  );
};

export default Sidenav;
