import { useState, useEffect } from "react";
import API_PATH from "../config/config";
import axios from "axios";
import authToken from "./useAuthToken";

const useMediaGalleryState = () => {
  const [mediaModalVisibility, setMediaModelVisibility] = useState(false);
  const [callBackParenContainer, setCallBackParenContainer] = useState(
    () => {}
  );
  const [showMediaEditor, setShowMediaEditor] = useState(false);
  const [meditEditorSelectedObject, setMeditEditorSelectedObject] = useState();

  const displayMediaEditorTool = (callback, obj) => {    
    setShowMediaEditor(true);
    setMeditEditorSelectedObject(obj);
    setCallBackParenContainer((callBackParenContainer) => [
      callBackParenContainer,
      callback,
    ]);
  };

  const triggerMediaEditorCallBack = (obj) => {
    if (
      callBackParenContainer != undefined &&
      callBackParenContainer.length > 0
    ) {
      callBackParenContainer[1](obj);
      setShowMediaEditor(false);      
    }
  };

  // useEffect(() => {
  //   console.log(" Local changed ... ", mediaModalVisibility);
  //   setShowMediaPicker(mediaModalVisibility);
  // }, [mediaModalVisibility]);

  return {
    displayMediaEditorTool,
    triggerMediaEditorCallBack,
    mediaModalVisibility,
    setMediaModelVisibility,
    meditEditorSelectedObject,    
    showMediaEditor,
    setShowMediaEditor,
  };
};

export default useMediaGalleryState;
