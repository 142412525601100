const reducerAmount = ( state = 100, action) => {    
    if( action.type === 'increment') {        
        return state + action.payload;
    } else if( action.type === 'remove') {
        return state.filter(item => item.id !== action.payload);       
    } 
    else if( action.type === 'decrement') {
        return state - action.payload;
    } else {
        return state;
    }
}

export default reducerAmount ;