import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Sidenav from "../components/Sidenav";
import { Collapse} from "antd";
import axios from "axios";
import { Spin } from "antd";
import ImageProcessComponent from "./../components/ImageProcessingTypeOne";
import authToken from "./../hook/useAuthToken";
import API_PATH  from "./../config/config"

var isCreateArt = false;

function Generate(props) {
  const {appToken} = authToken();
  const [loading, setLoading] = useState(false);
  const [isArt, setIsArt] = useState(true);
  const [authorData, setAuthorData] = useState([]);
  const [artData, setArtData] = useState(null);
  const [authorNames, setAuthorNames] = useState([]);
  const [singleAuthor, setSingleAuthor] = useState(null);
  const [showImageProcessing, setShowImageProcessing] = useState(false);
  const [processingData, setProcessingData] = useState(null);
  const [authorActive, setAuthorActive] = useState('');


  const { Panel } = Collapse;
  useEffect(() => {
    if(appToken){
      getAuthorsData();
    }
  }, [appToken]);
  const getAuthorsData = () => {

    let authornameArray = [];  
    setLoading(true);
    axios({
      method: "GET",
      url: `${API_PATH.IMAGE_UPLOAD_URL}/get_data/`,
      headers: {
        "Authorization": `Token ${appToken}`
      }
    })
      .then((res) => {
        if (res.status === 200) {
          setAuthorData(res.data);
          res.data.forEach((author) => {            
            Object.keys(author).map((key) => {
              authornameArray.push(key);
            });
          });          
          setAuthorNames(authornameArray);
          if( res.data && res.data[0] ) {
            const nameAuthor = authornameArray[0];
            setAuthorActive(nameAuthor);
            setSingleAuthor(res.data[0]);                
          }          
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };


  const changeAuthorList = (name) => {     
    setAuthorActive(name)
    setIsArt(true);
    setShowImageProcessing(false);
    let authorDataSet = authorData.find((author) => author[name]);
    setSingleAuthor(authorDataSet);    
  };
  const createArt = (id) => {
    //console.log("radio checked", e.target.value);
    setLoading(true);    
    setShowImageProcessing(true);
    axios({
      method: "POST",
      url: `${API_PATH.IMAGE_UPLOAD_URL}/url/${id}/`,
      headers: {
        "Authorization": `Token ${appToken}`
      }
    })
      .then((res) => {
        if (res.status == 200) {
          //console.log("data", res.data.data_points);
          fetch(res.data.data_points)
            .then((response) => response.json())
            .then((data) => {
              // jsonData is parsed json object received from url              
              setProcessingData(data);
              isCreateArt = true;
              setIsArt(false);
            })
            .catch((error) => {
              // handle your errors here
              console.error(error);
            });

          setArtData(res.data);
          setLoading(false);
          setIsArt(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <>
      <Sidenav modelTypesList={props.modelTypesList}/>   
      <main className="main-content">
        <Header />
        <div className="scrolldiv">
          <div className="d-grid generate ">
            <div className="tabs">
              <h4>Types</h4>
              <Collapse ghost defaultActiveKey={['1']}>
                <Panel header="Draw By Epicycles" key="1">
                  <div className="author-list" >
                                  {authorNames.map((name, key) => (
                                    <div
                                      className={authorActive === name ? 'actiive-author-name': "author-name"}
                                      key={key}
                                      onClick={() => changeAuthorList(name)}
                                    >
                                      {name}
                                    </div>
                                  ))}
                                </div>
              </Panel>    
            </Collapse>              
            </div>
            <div className="art-results">
              <Spin className="h-100" tip="Loading..." spinning={loading}>
                <div className="row">
                  {isArt ? (
                    <div className="col-md-12 gallery-container">
                      <div className="styles-wrap">
                        {singleAuthor &&
                          Object.keys(singleAuthor).map((key) => (
                            <div className="style-item" key={key}>
                              <h2>{key}</h2>
                              <div className="style-name">Landscape</div>
                              <div className="d-flex flex-wrap">
                                {singleAuthor[key]["Landscape"] &&
                                  singleAuthor[key]["Landscape"].map(
                                    (img, i) => (
                                      <div
                                        className="img-item"
                                        key={i}
                                        onClick={() => createArt(img.image_id)}
                                      >
                                        <img src={img.image_url} alt="" />
                                      </div>
                                    )
                                  )}
                              </div>
                              <div className="style-name">Portrait</div>
                              <div className="d-flex flex-wrap">
                                {singleAuthor[key]["Portrait"] &&
                                  singleAuthor[key]["Portrait"].map(
                                    (img, i) => (
                                      <div
                                        className="img-item"
                                        key={i}
                                        onClick={() => createArt(img.image_id)}
                                      >
                                        <img src={img.image_url} alt="" />
                                      </div>
                                    )
                                  )}
                              </div>
                              <div className="style-name">Still Life</div>
                              <div className="d-flex flex-wrap">
                                {singleAuthor[key]["Still Life"] &&
                                  singleAuthor[key]["Still Life"].map(
                                    (img, i) => (
                                      <div
                                        className="img-item"
                                        key={i}
                                        onClick={() => createArt(img.image_id)}
                                      >
                                        <img src={img.image_url} alt="" />
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* Image processing Layer --- */}                 
                  { showImageProcessing && (                                 
                  <div className="col-md-12 image-processing-container">
                    <div className="row">
                      {artData &&  (                        
                          <ImageProcessComponent data={processingData} artData={artData} title="Sharjeel is here ">
                          </ImageProcessComponent>                                                                          
                      )}                     
                    </div>
                  </div>
                  )}
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Generate;
