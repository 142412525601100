import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import "./../assets/css/template.css";
import API_PATH from './../config/config';
import {REGEX} from './../config/config';

import { faCheck, faTimes, faInfoCircle, faHospitalUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ForgotPassword = (props) => {
    const GENERATE_RESET_TOKEN = `${API_PATH.USER_MANAGEMENT}/reset_password_link/`;
    const RESET_PWD = `${API_PATH.USER_MANAGEMENT}/reset_password/`;    
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const homePageUrl = "/";
    const userRef = useRef();
    const errRef = useRef();
    const emailRef = useRef();
    const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(true);
    const [showResetPasswordForm, setShowResetPasswordForm] = useState(false)
    const showResetPassword = false;
    const [user, setUser] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [tokenText, setTokenText] = useState('');
    const [email, setEmail] = useState('');
    const [emailFocus, setEmailFocus] = useState(false);
    const [validEmail, setValidEmail] = useState(false);



    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);
    const [validToken, setValidToken] = useState(false);
    const [tokenFocus, setTokenFocus] = useState(false);

    useEffect(() => {
        // userRef.current.focus();
      if(emailRef && emailRef?.current){
        emailRef.current.focus();
      }
    }, []);

     useEffect(() => {
        setValidPwd(REGEX.PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd]);


    useEffect(() => {
       setValidToken(validToken != '');
       
    }, [tokenText]);

    // useEffect(() => {
    //     userRef.current.focus();
    // }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    useEffect(() => {
        setValidEmail(REGEX.EMAIL_REGEX.test(email));
    }, [email]);



    const listName = process.env.usertable ? process.env.usertable :  'users';
   
    const verifyPassword = async (e) => {
        setErrMsg('');
        e.preventDefault();            
        try {
            var loginApi = `${GENERATE_RESET_TOKEN}`;
            const data = {
                email : email
            }
            const res = fetch(loginApi, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson.type === "errors") {
                    setErrMsg(responseJson.message);            
                } else {
                    
                    setShowForgotPasswordForm(false);
                    setShowResetPasswordForm(true);  
                }                               
            })
            .catch((error) => {
                console.log(error);
                // if(error.response.data.type === "errors") {
                //     setErrMsg(error.response.data.message);
                // } else {
                //     setErrMsg('Reset process failed.');
                // }                
            });
        }catch (err) {

        }
    };

    const handleSubmit = async (e) => {
        setErrMsg('');
        e.preventDefault();            

        if( email == '' || pwd == ''  || matchFocus == '' || tokenText === '' ){
            setErrMsg("Information is not complete. Please check again.");
            return false;            
        }
        try {
            var loginApi = `${RESET_PWD}`;
            const data = {
                email : email,
                password : pwd, 
                token : tokenText,
                confirm_password : matchPwd
            }

            const res = fetch(loginApi, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((responseJson) => {
                
                if( responseJson.type && responseJson.type === 'errors') {                    
                    
                    if( responseJson.message && responseJson.message != '') {
                        const msgError = responseJson.message;
                        setErrMsg(msgError);
                    } else{
                        setErrMsg('There is issue in service.');
                    }
                } else {                    
                    
                    setUser('');
                    setPwd('');
                    const data = responseJson?.data;  
                    localStorage.setItem("processingToken", JSON.stringify(data));                              
                    navigate("/login", { replace: true });
                }
                
            })
            .catch((error) => {
                setUser('');
                setPwd('');
                console.error(error);
                setErrMsg('Login Failed');
            });
            
        } catch (err) {           
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    return (
        <>
        { showForgotPasswordForm && ( 
        <div className="resetpwd formPage forgotpassword ">
            <div className="bgPage"></div>
            <section className="page section sectiontest tm-template"> 
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <h1>Forgot Password</h1>
                <form onSubmit={verifyPassword}>
                <label htmlFor="email">
                            Email :
                            <FontAwesomeIcon icon={faCheck} className={validEmail ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validEmail || !email ? "hide" : "invalid"} />
                        </label>
                        <input 
                            type="email"
                            name="email"
                            placeholder="Enter your email address"
                            id="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="on"
                            ref={emailRef}
                            aria-invalid={validEmail ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setEmailFocus(false)}
                            onBlur={() => setEmailFocus(true)}
                            required />
                            <p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Invalid Email.<br />                            
                        </p>
                     <button className="button-submit">Send Request</button>
                </form>
                </section>
                
                </div>
                 )}
                  { showResetPasswordForm && ( 
        <div className="resetpwd formPage forgotpassword">
            <div className="bgPage"></div>
            <section className="page section sectiontest tm-template"> 
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                
                <h1>Reset Password</h1>
                <p className="resetemail-text">We have sent you token, please check your email and paste that here.</p>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="password">
                            Password:
                            <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                            aria-invalid={validPwd ? "false" : "true"}
                            aria-describedby="pwdnote"
                            onFocus={() => setPwdFocus(false)}
                            onBlur={() => setPwdFocus(true)}
                        />
                        <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            8 to 24 characters.<br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                        </p>


                        <label htmlFor="confirm_pwd">
                            Confirm Password:
                            <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="password"
                            id="confirm_pwd"
                            onChange={(e) => setMatchPwd(e.target.value)}
                            value={matchPwd}
                            required
                            aria-invalid={validMatch ? "false" : "true"}
                            aria-describedby="confirmnote"
                            onFocus={() => setMatchFocus(false)}
                            onBlur={() => setMatchFocus(true)}
                        />
                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must match the first password input field.
                        </p>

                    <label htmlFor="token">Token:</label>
                    <input
                            type="text"
                            id="token"
                            onChange={(e) => setTokenText(e.target.value)}
                            value={tokenText}
                            required
                            aria-invalid={validToken ? "false" : "true"}
                            aria-describedby="confirmnote"
                            onFocus={() => setTokenFocus(false)}
                            onBlur={() => setTokenFocus(true)}
                        />
                        <p id="confirmnote" className={tokenFocus && (tokenText === '') ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            should not be empty {validToken} - {tokenFocus}
                        </p>

                    <button className="button-submit">Reset Password</button>
            </form>
            <p>
               
                <span className="line">
                    <Link to="/login">Login</Link>
                </span>
            </p>
        </section>
        </div>
                  )}
        </>
    )
}

export default ForgotPassword;