import React, { useState, useEffect } from "react";

import { Button, Form, Input, Col, Divider, Row } from "antd";
import { InputNumber } from "antd";
import API_PATH from "./../config/config";
import axios from "axios";
import { Spin } from "antd";
import authToken from "./../hook/useAuthToken";
import { LoadingOutlined } from "@ant-design/icons";
const TextToImage = () => {
  const { appToken } = authToken();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  const onFinish = (values) => {
    setLoading(true);
    setImages([]);
    axios({
      method: "GET",
      url: `${API_PATH.GAN_IMAGE_MODELS}text2image/`,
      headers: {
        Authorization: `Token ${appToken}`,
      },
      params: {
        text: values.text,
        batch_size: 2,
        seed: values.seed,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          // console.log("res", res.data["64encoded_images"]);
          setImages(res.data["64encoded_images"]);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <main className="main-content">
      <div className="scrolldiv relative">
        <div className="heading-selected-generator">
          <h3>Text To Image</h3>
        </div>

        <div className="page-fluid">
          <div className="inline-form">
            <Form name="basic" onFinish={onFinish}>
              <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    label="Enter Text (Tokens Limit 77)"
                    name="text"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Text!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col className="gutter-row">
                  <Form.Item label="Seed Integer" name="seed">
                    <InputNumber min={1} />
                  </Form.Item>
                </Col>
                <Col className="gutter-row">
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="images-grid">
            {images && images.length > 0 ? (
              <figure>
                {images.map((image, key) => {
                  return (
                    <img
                      src={`data:image/png;base64,${image}`}
                      key={key}
                      alt=""
                    />
                  );
                })}
              </figure>
            ) : (
              ""
            )}
            {loading ? (
              <div className="loader-inline">
                <LoadingOutlined />
                <span>Fetching results...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default TextToImage;
