import {combineReducers} from 'redux';
import reducerGenerator from "./generatorReducer";
import amountGenerator from "./amountReducer";
import collectionReducer from "./collectionReducer";
import drawerPreviewReducer from "./drawerPreviewReducer";
import settingsReducer from "./settingsReducer";

const allReducers = combineReducers({
    generator : reducerGenerator, 
    amount : amountGenerator, 
    collection : collectionReducer, 
    drawerPreview : drawerPreviewReducer,
    settings : settingsReducer
});

export default allReducers;