import React from "react";
import styles from "./../../assets/css/modals.css";
import { RiAlertLine } from "react-icons/ri";

const ConfirmationModel = ({ showModal, content, onSubmit , type}) => {  
  return (
    <>
    <div >{styles.darkBG}</div>
      <div className="darkBG modal-preview-bg" onClick={() => showModal(false)} />      
      <div className="centered modal-confirmation  fadein-animation">
      <div className={[type, "iconTop"].join(' ')}><RiAlertLine style={{ marginBottom: "-3px" }} /></div>
        <div className="modal">
          <div className="modalHeader">
            <h5 className="modal-heading">Confirmation</h5>
          </div>
          <button className="closeBtn" onClick={() => showModal(false)}>            
          </button>
          <div className="modalContent">
            <div className="center">
                <div>{content}</div>                
            </div>            
          </div>                    
          <div className="action-buttons">  
                <button onClick={() => { onSubmit()}}> Yes</button>
                <button className="cancel" onClick={() => showModal(false)}> No</button>
          </div>
        </div>
      </div>
    </>
  );
};
 
export default ConfirmationModel;