import React, { useEffect, useState } from "react";
import styles from "./../../assets/css/modals.css";
import { RiCloseLine } from "react-icons/ri";
import { CgMoveDown } from "react-icons/cg";
import useMediaGalleryState from "../../hook/useMediaGalleryState";
import useMediaEditToolState from "../../hook/useMediaEditToolState";
import useInPaintingToolState from "../../hook/useInPaintingToolState";
import { useBetween } from "use-between";

import API_PATH from "./../../config/config";
import axios from "axios";
import authToken from "./../../hook/useAuthToken";

const ImageDifferenceModal = ({
  closefn,
  inputImage,
  outPutImage,
  media,
  selectedMediaType,
  resetItemVal,
  itemIndex,
}) => {
  const { setSelectedMedia, setMediaModelVisibility, displayMediaModel } =
    useBetween(useMediaGalleryState);

  const { displayMediaEditorTool, setShowMediaEditor } = useBetween(
    useMediaEditToolState
  );

  const { displayInPaintingTool } = useBetween(useInPaintingToolState);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [outPutImageVal, setOutPutImageVal] = useState(outPutImage);
  const [reloadOutPutImage, setReloadOutPutImage] = useState(true);
  const { appToken } = authToken();
  const [inPaintingObj, setInPaintingObj] = useState({
    originalHeight: "",
    originalWidth: "",
    id: "",
    imgSrc: "",
    overlayImage: "",
  });

  useEffect(() => {}, [outPutImageVal]);
  useEffect(() => {
    if (media && media.id) {
      loadImage(media.input_url, media);
    }
  }, [media]);
  const callBackMediaSelection = (obj) => {
    const mediaList = [
      {
        obj: {
          blending_image: media.input_url,
          blending_image_name: media.name,
          blending_image_url: media.input_url,
          id: media.id,
        },
        originalHeight: media.originalHeight,
        originalWidth: media.originalWidth,
        type: "background",
      },
      {
        obj: obj,
        originalHeight: 80,
        originalWidth: 80,
        type: "overlay",
      },
    ];

    showMediaEditorTool(mediaList);
  };

  const loadInPintinTool = (img, media) => {
    //  console.log("Media here ", media);
    // loadImage(img, inPaintingObj);
  };
  const loadImage = (imageUrl, obj) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      obj.originalHeight = img.height;
      obj.originalWidth = img.width;
      console.log(obj);
    };
    img.onerror = (err) => {
      console.log("img error");
      console.error(err);
    };
  };

  const callBackMediaEditorTool = (obj) => {
    const data = {
      json_data: {
        image_id: obj.bg.id,
        object_id: obj.overlay.id,
        x: Math.round(obj.overlay.x),
        y: Math.round(obj.overlay.y),
        theta: obj.overlay.rotation ? Math.round(obj.overlay.rotation) * -1 : 0,
        width: Math.round(obj.overlay.width),
        height: Math.round(obj.overlay.height),
        bg_height: Math.round(obj.bg.height),
        bg_width: Math.round(obj.bg.width),
      },
    };
    setShowLoadingSpinner(true);
    axios({
      method: "POST",
      url: `${API_PATH.BLEND_IMAGE}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${appToken}`,
      },
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((res) => {
        if (res.data) {
        }
        setReloadOutPutImage(false);

        const newOutPutImage = res.data.blended_url + "?v=" + Date.now();
        const newOutPutImageThumb = res.data.blended_url + "?v=" + Date.now();
        setOutPutImageVal(newOutPutImage);
        media = {
          ...media,
          output_thumbnail_url: newOutPutImage,
          output_url: newOutPutImageThumb,
        };

        // console.log("NEW " , media);
        resetItemVal(media);
        setShowLoadingSpinner(false);
        setReloadOutPutImage(true);
      })
      .catch((err) => {
        console.log(err);
        setShowLoadingSpinner(false);
      });
  };

  const callBackInPTaining = (obj) => {
    setShowLoadingSpinner(true);
    axios({
      method: "POST",
      url: `${API_PATH.INPAINT_IMAGE_API}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${appToken}`,
      },
      body: JSON.stringify(obj),
      data: JSON.stringify(obj),
    })
      .then((res) => {
        setReloadOutPutImage(false);
        const newOutPutImage = res.data.output_url + "?v=" + Date.now();
        const newOutPutImageThumb =
          res.data.output_thumbnail_url + "?v=" + Date.now();
        setOutPutImageVal(newOutPutImage);
        media = {
          ...media,
          output_thumbnail_url: newOutPutImageThumb,
          output_url: newOutPutImage,
        };
        resetItemVal(media);
        setShowLoadingSpinner(false);
        setReloadOutPutImage(true);
      })
      .catch((err) => {
        console.log(err);
        setShowLoadingSpinner(false);
      });
  };

  const showMediaEditorTool = (obj) => {
    setShowMediaEditor(true);
    displayMediaEditorTool(callBackMediaEditorTool, obj);
  };

  const showInPaintingTool = () => {
    displayInPaintingTool(callBackInPTaining, media);
  };

  const showMediaGallery = () => {
    displayMediaModel(callBackMediaSelection);
    setMediaModelVisibility(true);
  };
  return (
    <>
      <div>{styles.darkBG}</div>
      <div className="darkBG modal-preview-bg" onClick={() => closefn(false)} />
      <div className="centered modal-preview">
        {showLoadingSpinner && (
          <div className="loadingSpinner">
            <div className="loadingio-spinner-dual-ball-2g65rckwnch">
              <div className="ldio-jviut9h3lgc">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        )}
        <div className="modal">
          <div className="modalHeader">
            <h5 className="modal-heading">Preview</h5>
          </div>
          {selectedMediaType === "BLENDING" && (
            <div className="modal-top-action-btn ">
              <button
                onClick={() => {
                  showMediaGallery();
                }}
              >
                {" "}
                Reset Blending{" "}
              </button>
            </div>
          )}
          {selectedMediaType === "INPAINTING" && (
            <div className="modal-top-action-btn ">
              <button
                onClick={() => {
                  showInPaintingTool();
                }}
              >
                {" "}
                Start Masking{" "}
              </button>
            </div>
          )}
          <button className="closeBtn" onClick={() => closefn(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
            <div className="modal-flex center">
              <div
                onLoad={() => {
                  loadInPintinTool(inputImage, media);
                }}
              >
                <img src={inputImage} />
              </div>
              <div className="convert-bar">
                <CgMoveDown />
              </div>
              <div>{reloadOutPutImage && <img src={outPutImageVal} />}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageDifferenceModal;
