import { useRef, useState, useEffect } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
  faHospitalUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Link } from "react-router-dom";
import "./../assets/css/template.css";
import API_PATH from "./../config/config";

const USER_REGEX = /^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*$/;
const EMAIL_REGEX =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = `${API_PATH.USER_MANAGEMENT}/register/`;

const Register = () => {
  const userRef = useRef();
  const emailRef = useRef();
  const errRef = useRef();
  const [btnClicked, setBtnClicked] = useState(false);
  const [user, setUser] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [email, setEmail] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [validEmail, setValidEmail] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // userRef.current.focus();
    if (emailRef && emailRef?.current) {
      emailRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd, matchPwd]);

  const listName = process.env.usertable ? process.env.usertable : "users";
  const registerUser = async (userInfo) => {
    return new Promise((resolve, reject) => {
      let userList = localStorage.getItem(listName);
      if (userList != undefined) {
        userList = JSON.parse(userList);
        if (userList.length > 0) {
          const userExisits = userList.some(
            (user) => user.username == userInfo.username
          );
          if (userExisits) {
            resolve(false);
          } else {
            userList.push({
              id: userList.length + 1,
              username: userInfo.username,
              password: userInfo.pwd,
              email: "",
            });
            localStorage.setItem(listName, JSON.stringify(userList));
            console.log(localStorage.getItem(listName));
            resolve(true);
          }
        }
      } else {
        const list = [];
        list.push({
          id: 0,
          username: userInfo.username,
          password: userInfo.pwd,
          email: "",
        });
        localStorage.setItem(listName, JSON.stringify(list));
        resolve(true);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let userEmail = email.match(/^([^@]*)@/)[1];
    let randomStr = (Math.random() + 1).toString(36).substring(7);
    const newUsername = userEmail + randomStr;

    // if button enabled with JS hack
    const v1 = USER_REGEX.test(newUsername);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2) {
      setErrMsg("Invalid Entry");
      return;
    }

    registerUser({ username: newUsername, pwd: pwd, email: "" }).then(
      (result) => {
        console.log(result);
        if (result) {
          setErrMsg("Error!");
        } else {
          setErrMsg("User name is not available");
        }
      }
    );
    /**
     * TODO replace with apicall
     */
    try {
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({ email, username: newUsername, password: pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      // TODO: remove console.logs before deployment
      setSuccess(true);
      //clear state and controlled inputs
      setUser("");
      setPwd("");
      setMatchPwd("");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed,email already exists");
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      <div className="formPage">
        <div className="bgPage"></div>
        {success ? (
          <section className="page section sectiontest tm-template success">
            <h1 className="success-h1">You are successfully registered.</h1>
            <h3 className="success-h3">
              Please check you email for verification.
            </h3>
            <p>
              <a href="./login">Log In</a>
            </p>
          </section>
        ) : (
          <section className="page section sectiontest tm-template">
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <h1>Register</h1>
            <form onSubmit={handleSubmit}>
              <label htmlFor="email">
                Email :
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validEmail ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={validEmail || !email ? "hide" : "invalid"}
                />
              </label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email address"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="on"
                ref={emailRef}
                aria-invalid={validEmail ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setEmailFocus(false)}
                onBlur={() => setEmailFocus(true)}
                required
              />
              <p
                id="uidnote"
                className={
                  emailFocus && email && !validEmail
                    ? "instructions"
                    : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                Invalid Email.
                <br />
              </p>
              {/* <label htmlFor="username">
                            Username:
                            <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validName || !user ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setUserFocus(faHospitalUser)}
                            onBlur={() => setUserFocus(true)}
                        />
                        <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            4 to 24 characters.<br />
                            Must begin with a letter.<br />
                            Letters, numbers, underscores, hyphens allowed.
                        </p> */}

              <label htmlFor="password">
                Password:
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validPwd ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={validPwd || !pwd ? "hide" : "invalid"}
                />
              </label>
              <input
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(false)}
                onBlur={() => setPwdFocus(true)}
              />
              <p
                id="pwdnote"
                className={pwdFocus && !validPwd ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                8 to 24 characters.
                <br />
                Must include uppercase and lowercase letters, a number and a
                special character.
                <br />
                Allowed special characters:{" "}
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span>
              </p>

              <label htmlFor="confirm_pwd">
                Confirm Password:
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validMatch && matchPwd ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={validMatch || !matchPwd ? "hide" : "invalid"}
                />
              </label>
              <input
                type="password"
                id="confirm_pwd"
                onChange={(e) => setMatchPwd(e.target.value)}
                value={matchPwd}
                required
                aria-invalid={validMatch ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setMatchFocus(false)}
                onBlur={() => setMatchFocus(true)}
              />
              <p
                id="confirmnote"
                className={
                  matchFocus && !validMatch ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                Must match the first password input field.
              </p>

              <button
                className="button-submit"
                disabled={!validPwd || !validMatch ? true : false}
              >
                Sign Up
              </button>
            </form>
            <p>
              Already registered?
              <br />
              <span className="line">
                <Link to="/login">Sign In</Link>
              </span>
            </p>
          </section>
        )}
      </div>
    </>
  );
};

export default Register;
