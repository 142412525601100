import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  Navigate,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import authToken from "./../hook/useAuthToken";
import API_URL from "./../config/config";
import { UploadOutlined, RadiusSettingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import { BsFileEarmarkZip, BsFileEarmarkImage } from "react-icons/bs";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import useUpdateCollectionDrawer from "../hook/useUpdateCollectionDrawer";
import { useBetween } from "use-between";
import { useSelector, useDispatch } from "react-redux";
import collectionActions from "./../state/actions/collectionActions";
import settingsActions from "./../state/actions/settingActions";

export default function CreateCollectionDrawer(props) {
  const dispatch = useDispatch();
  const drawerObject = useSelector((state) => state.settings);
  const {
    showDrawer,
    setShowDrawer,
    stateObj,
    collectionFieldName,
    callBackFunctions,
  } = useBetween(useUpdateCollectionDrawer);
  const navigation = useNavigate();
  const location = useLocation();

  const { appToken } = authToken();
  const [generatorData, setGeneratorData] = useState(stateObj);

  const [fileList, setFileList] = useState([]);
  const [title, setTitle] = useState("DAY2NIGHT");
  const [mode, setMode] = useState(false);
  const [uploadInprogress, setUploadInProgress] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const API_PATH = "/api/gan_image_models/collections/";
  const [imageData, setImageData] = useState([]);
  const [collectionName, setCollectionName] = useState("");
  const [modelTypes, setModelTypes] = useState([]);
  const [fileName, setFileName] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("ZIP");
  const [selectedMediaType, setSelectedMediaType] = useState("");
  const [showImageEditor, setShowimageEditor] = useState(false);
  const [showMediaGallery, setShowMediaGallery] = useState(false);
  const [blendingMediaSelection, setBlendingMediaSelection] = useState("");
  const [blendingSelectedObject, setBlendingSelectedObject] = useState([
    {
      type: "background",
      obj: null,
    },
    {
      type: "overlay",
      obj: null,
    },
  ]);

  const [processData, setProcessData] = useState([]);

  const [showFormm, setShowForm] = useState(
    props.show != undefined && props.show ? props.show : false
  );

  const onClose = () => {
    dispatch(settingsActions.toggleModifyCollectionDrawer(false));
    // props.setShow(false);
  };

  useEffect(() => {
    console.log(drawerObject);
    console.log(drawerObject.drawerState);
    setFileName("");
    setCollectionName(collectionFieldName);
    setGeneratorData(stateObj);
  }, [stateObj]);

  const loadImage = (imageUrl, obj) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      obj.originalHeight = img.height;
      obj.originalWidth = img.width;
    };
    img.onerror = (err) => {
      console.error(err);
    };
  };

  useEffect(() => {
    if (generatorData && generatorData.type) {
      setSelectedMediaType(generatorData.type);
      setTitle(generatorData.type);
    }
  }, [generatorData]);
  useEffect(() => {
    if (appToken && props.show) {
      loadMedelsTypes();
    }

    if (
      generatorData != undefined &&
      generatorData.collection != undefined &&
      generatorData.collection &&
      generatorData.collection.name
    ) {
      setCollectionName(generatorData.collection.name);
    }
    parseMediaData();
  }, [processData, appToken]);

  const openImageEditor = () => {
    setShowimageEditor(true);
  };
  const loadMedelsTypes = () => {
    axios({
      method: "GET",
      url: `${API_URL.GAN_IMAGE_MODELS}model_types/`,
      headers: {
        Authorization: `Token ${appToken}`,
      },
    }).then((res) => {
      setModelTypes(res.data.model_types);
    });
  };
  const parseMediaData = function () {
    if (
      processData.generator &&
      processData?.generator?.collection &&
      processData?.generator?.collection[0] &&
      processData?.generator?.collection[0].images.length > 0
    ) {
      setImageData(processData?.generator?.collection[0].images);
    }
  };

  const resetUploadFile = (files) => {
    if (files.length > 0) {
      if (
        files[0].type.indexOf("image") == -1 &&
        files[0].type.indexOf("zip") == -1
      ) {
        showErrorMessage("Only select image or Zip file.");
        files = [];
        setFileList([]);
        setFileName("");
        setSelectedFileType("");
        return false;
      }
      setFileList(files);
      setFileName(files[0].name);
      setSelectedFileType(
        files[0].type.indexOf("image") > -1 ? "IMAGE" : "ZIP"
      );
      setUploadError(false);
    }
  };

  const showErrorMessage = (message) => {
    setErrorMessage(message);
    setUploadError(true);
    setUploadInProgress(false);
  };
  const resetForm = () => {
    setUploadInProgress(false);
    setUploadError(false);
  };

  const processAttachedMedia = () => {
    resetForm();
    if (collectionName == "") {
      showErrorMessage("Enter collection name.");
      return;
    }
    setUploadInProgress(true);
    let formData = new FormData();
    if (fileList.length > 0 && collectionName != "") {
      formData.append("zip_file", fileList[0]);
      formData.append("name", collectionName);
      formData.append("model_type", title);
      formData.append("generator", generatorData.id);
      formData.append("file_type", selectedFileType);
      axios({
        method: "post",
        url: API_PATH,
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          Authorization: `Token ${appToken}`,
        },
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            resetForm();
            if (response.data) {
              setProcessData(response.data);
            } else {
              setProcessData(response);
            }

            if (
              window.location.pathname &&
              window.location.pathname.indexOf("collection-preview-page") > -1
            ) {
              dispatch(collectionActions.reloadCollection(true));
              onClose();
            } else {
              onClose();
              navigation("/collection-preview-page", {
                state: {
                  id: generatorData.id ? generatorData.id : 0,
                  name: generatorData.name ? generatorData.name : "",
                  type: title,
                  collection: response.data,
                },
              });
            }
          } else {
            showErrorMessage(response.message);
          }
        })
        .catch((err) => {
          if (err.response.data.message) {
            showErrorMessage(err.response.data.message);
          } else {
            showErrorMessage(err.message);
          }
        });
    } else {
      showErrorMessage("Please select a file to upload");
    }
  };

  const setModelTypeHandler = (value) => {
    setTitle(value);
  };

  const checkIsBlendingModelType = () => {};
  return (
    <>
      {/* { props.show && ( */}
      <Drawer
        title="Add/Update Collection"
        width={600}
        onClose={onClose}
        visible={props.show}
        bodyStyle={{
          paddingBottom: 80,
        }}
        // extra={
        //   <Space>
        //     <Button onClick={onClose}>Cancel</Button>
        //     <Button onClick={onClose} type="primary">
        //       Submit
        //     </Button>
        //   </Space>
        // }
      >
        <Row gutter={16}>
          <div className="generators full-width">
            <div className="row">
              <div style={{ display: "flex", flexDirection: "column" }}>
                {uploadError && (
                  <div
                    data-show="true"
                    className="ant-alert ant-alert-error ant-alert-with-description ant-alert-no-icon error-bar"
                    role="alert"
                  >
                    <div className="ant-alert-content">
                      <div className="ant-alert-message">Error Message:</div>
                      <div className="ant-alert-description">
                        {errorMessage}
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        resetForm();
                      }}
                      type="button"
                      className="ant-alert-close-icon"
                      tabindex="0"
                    >
                      <span
                        role="img"
                        aria-label="close"
                        className="anticon anticon-close"
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="close"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                )}
                <div className="form-upload ">
                  {uploadInprogress && (
                    <section className="code-box-demo">
                      <div className="ant-spin-nested-loading">
                        <div>
                          <div
                            className="ant-spin ant-spin-spinning ant-spin-show-text"
                            aria-live="polite"
                            aria-busy="true"
                          >
                            <span className="ant-spin-dot ant-spin-dot-spin">
                              <i className="ant-spin-dot-item"></i>
                              <i className="ant-spin-dot-item"></i>
                              <i className="ant-spin-dot-item"></i>
                              <i className="ant-spin-dot-item"></i>
                            </span>
                            <div className="ant-spin-text">Processing...</div>
                          </div>
                        </div>
                        <div className="ant-spin-container ant-spin-blur">
                          <div
                            data-show="true"
                            className="ant-alert ant-alert-info ant-alert-with-description ant-alert-no-icon"
                            role="alert"
                          >
                            <div className="ant-alert-content">
                              <div className="ant-alert-message">
                                Upload in propgress
                              </div>
                              <div className="ant-alert-description">
                                Wait for few seconds .
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                  {!uploadInprogress && (
                    <div className="upload-form-column">
                      <div className="item">
                        <div className="main">
                          <div className="title-bar">
                            <h2 className="title margin-auto text-truncate">
                              Upload Collection for&nbsp;
                              {generatorData && (
                                <span
                                  onClick={() => {
                                    navigation("/collection-preview-page", {
                                      state: generatorData,
                                    });
                                  }}
                                >
                                  {generatorData.name ? generatorData.name : ""}
                                </span>
                              )}
                            </h2>
                          </div>
                        </div>
                        <div className="main">
                          <label
                            className="ant-form-item-required ant-form-item-required-mark-optional"
                            title="Field A"
                          >
                            Choose Collection Name
                          </label>
                        </div>
                        <div className="main">
                          <input
                            className="ant-input  ant-form-item-control"
                            id="collection"
                            maxLength="100"
                            onChange={(e) => setCollectionName(e.target.value)}
                            value={collectionName}
                            placeholder="Collection name"
                            type="text"
                          />
                        </div>
                        <div className="main">
                          <label
                            className="ant-form-item-required ant-form-item-required-mark-optional"
                            title="Field A"
                          >
                            Choose Model Type
                          </label>
                        </div>
                        <div className="main">
                          <select
                            className="ant-col ant-form-item-control"
                            id="title"
                            value={title}
                            onChange={(e) => {
                              setModelTypeHandler(e.target.value);
                            }}
                          >
                            {props.modelTypesList != undefined &&
                              props.modelTypesList.map((type, key) => (
                                <option
                                  name={selectedMediaType}
                                  key={key}
                                  value={type}
                                >
                                  {type}
                                </option>
                              ))}
                          </select>
                        </div>
                        {fileName != "" && (
                          <div
                            className="ant-row ant-form-item file-name"
                            alt={fileName}
                          >
                            <div className="text">{fileName}</div>
                            {fileName.indexOf(".zip") > -1 && (
                              <BsFileEarmarkZip />
                            )}
                            {fileName.indexOf(".zip") == -1 && (
                              <BsFileEarmarkImage />
                            )}
                          </div>
                        )}
                      </div>
                      <div className="item btn-upload-bar">
                        <div className="upload-btn ant-col ant-form-item-control">
                          <input
                            className="uploadFileBtn"
                            type="file"
                            name="file1"
                            accept="image/*,.zip,.tar"
                            id="file"
                            onChange={(e) => resetUploadFile(e.target.files)}
                          />
                          <Button
                            icon={<UploadOutlined />}
                            className="ant-btn ant-btn-primary ant-btn-dangerous"
                          >
                            Select File
                          </Button>
                        </div>
                        {fileName != "" && (
                          <div
                            className="ant-col ant-form-item-control"
                            onClick={() => {
                              processAttachedMedia();
                            }}
                          >
                            <Button
                              icon={<RadiusSettingOutlined />}
                              className="ant-btn-primary"
                            >
                              Start
                            </Button>
                          </div>
                        )}

                        {(!fileName || fileName == "") && (
                          <div
                            className="ant-col ant-form-item-control disable"
                            onClick={() => {
                              // processAttachedMedia();
                            }}
                          >
                            <Button
                              icon={<RadiusSettingOutlined />}
                              className="ant-btn-primary"
                            >
                              Start
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {/* <section className="code-box-demo">
                  <div className="site-card-wrapper processed-image-cards">
                    <div className="ant-row ant-card-panel">
                      {imageData.map((media) => (
                        <UploadImagePreview media={media} />
                      ))}
                    </div>
                  </div>
                </section> */}
              </div>
            </div>
          </div>
        </Row>
        {/* <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter user name",
                    },
                  ]}
                >
                  <Input placeholder="Please enter user name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="url"
                  label="Url"
                  rules={[
                    {
                      required: true,
                      message: "Please enter url",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "100%",
                    }}
                    addonBefore="http://"
                    addonAfter=".com"
                    placeholder="Please enter url"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="owner"
                  label="Owner"
                  rules={[
                    {
                      required: true,
                      message: "Please select an owner",
                    },
                  ]}
                >
                  <Select placeholder="Please select an owner">
                    <option value="xiao">Xiaoxiao Fu</option>
                    <option value="mao">Maomao Zhou</option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                    {
                      required: true,
                      message: "Please choose the type",
                    },
                  ]}
                >
                  <Select placeholder="Please choose the type">
                    <option value="private">Private</option>
                    <option value="public">Public</option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="approver"
                  label="Approver"
                  rules={[
                    {
                      required: true,
                      message: "Please choose the approver",
                    },
                  ]}
                >
                  <Select placeholder="Please choose the approver">
                    <option value="jack">Jack Ma</option>
                    <option value="tom">Tom Liu</option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="dateTime"
                  label="DateTime"
                  rules={[
                    {
                      required: true,
                      message: "Please choose the dateTime",
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    style={{
                      width: "100%",
                    }}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "please enter url description",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    placeholder="please enter url description"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form> */}
      </Drawer>
      {/* )} */}
    </>
  );
}
