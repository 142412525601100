import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  Navigate,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Upload, Button, message, UploadProps } from "antd";
import { UploadOutlined, RadiusSettingOutlined } from "@ant-design/icons";
import Header from "../components/Header";
import Sidenav from "../components/Sidenav";
import UploadImagePreview from "./UploadImagePreview";
import axios from "axios";
import authToken from "./../hook/useAuthToken";
import API_URL from "./../config/config";
import { BsFileEarmarkZip, BsFileEarmarkImage } from "react-icons/bs";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import ImageEditorModal from "./modals/ImageEditorModal";
import MediaGalleryModel from "./modals/MediaGalleryModel";

import { GrNext, GrPrevious } from "react-icons/gr";

function Uploader(props) {
  const navigation = useNavigate();
  const location = useLocation();
  const { appToken } = authToken();
  const generatorData = location.state;

  const [fileList, setFileList] = useState([]);
  const [title, setTitle] = useState("DAY2NIGHT");
  const [mode, setMode] = useState(false);
  const [uploadInprogress, setUploadInProgress] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const API_PATH = "/api/gan_image_models/collections/";
  const [imageData, setImageData] = useState([]);
  const [collectionName, setCollectionName] = useState("");
  const [modelTypes, setModelTypes] = useState([]);
  const [fileName, setFileName] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("ZIP");
  const [selectedMediaType, setSelectedMediaType] = useState("");
  const [showImageEditor, setShowimageEditor] = useState(false);
  const [showMediaGallery, setShowMediaGallery] = useState(false);
  const [blendingMediaSelection, setBlendingMediaSelection] = useState("");
  const [blendingSelectedObject, setBlendingSelectedObject] = useState([
    {
      type: "background",
      obj: null,
    },
    {
      type: "overlay",
      obj: null,
    },
  ]);

  const [showPickBlendingImage, setShowPickBlendingImage] = useState(false);

  const [bgImageURL, setBgImageURL] = useState("");

  const [overLayImageState, setOverLayImageState] = useState("");
  const [processData, setProcessData] = useState([]);

  const updateBlendingImagePositioning = (obj) => {};

  const loadImage = (imageUrl, obj) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      obj.originalHeight = img.height;
      obj.originalWidth = img.width;
    };
    img.onerror = (err) => {
      console.error(err);
    };
  };

  const resetBlendedMediaData = (obj) => {
    if (blendingMediaSelection === "background") {
      blendingSelectedObject[0].obj = obj;
      setBgImageURL(obj.blending_image_url);
      loadImage(obj.blending_image_url, blendingSelectedObject[0]);
    } else {
      blendingSelectedObject[1].obj = obj;
      setOverLayImageState(obj.blending_image_url);
      loadImage(obj.blending_image_url, blendingSelectedObject[1]);
    }
    setShowMediaGallery(false);
  };

  const openMediaPickerBox = (flag, type) => {
    setShowMediaGallery(flag);
    setBlendingMediaSelection(type);
  };
  const setModelTypeHandler = (value) => {
    setTitle(value);
    if (value === "BLENDING") {
      setShowPickBlendingImage(true);
    } else {
      setShowPickBlendingImage(false);
    }
  };

  const checkIsBlendingModelType = () => {
    if (title === "BLENDING") {
      setShowPickBlendingImage(true);
    } else {
      setShowPickBlendingImage(false);
    }
  };

  useEffect(() => {
    if (location.state && location.state.type) {
      setSelectedMediaType(location.state.type);
      setTitle(location.state.type);
      checkIsBlendingModelType();
    }
  }, [location.state]);
  useEffect(() => {
    if (appToken) {
      loadMedelsTypes();
    }
    if (location.state.collection && location.state.collection.name) {
      setCollectionName(location.state.collection.name);
    }
    parseMediaData();
  }, [processData, appToken]);

  const openImageEditor = () => {
    setShowimageEditor(true);
  };
  const loadMedelsTypes = () => {
    axios({
      method: "GET",
      url: `${API_URL.GAN_IMAGE_MODELS}model_types/`,
      headers: {
        Authorization: `Token ${appToken}`,
      },
    }).then((res) => {
      setModelTypes(res.data.model_types);
    });
  };
  const parseMediaData = function () {
    if (
      processData.generator &&
      processData?.generator?.collection &&
      processData?.generator?.collection[0] &&
      processData?.generator?.collection[0].images.length > 0
    ) {
      setImageData(processData?.generator?.collection[0].images);
    }
  };

  const resetUploadFile = (files) => {
    if (files.length > 0) {
      if (
        files[0].type.indexOf("image") == -1 &&
        files[0].type.indexOf("zip") == -1
      ) {
        showErrorMessage("Only select image or Zip file.");
        files = [];
        setFileList([]);
        setFileName("");
        setSelectedFileType("");
        return false;
      }
      setFileList(files);
      setFileName(files[0].name);
      setSelectedFileType(
        files[0].type.indexOf("image") > -1 ? "IMAGE" : "ZIP"
      );
      setUploadError(false);
    }
  };

  const showErrorMessage = (message) => {
    setErrorMessage(message);
    setUploadError(true);
    setUploadInProgress(false);
  };
  const resetForm = () => {
    setUploadInProgress(false);
    setUploadError(false);
  };

  const processAttachedMedia = () => {
    resetForm();
    if (collectionName == "") {
      showErrorMessage("Enter collection name.");
      return;
    }
    setUploadInProgress(true);
    let formData = new FormData();
    if (fileList.length > 0 && collectionName != "") {
      formData.append("zip_file", fileList[0]);
      formData.append("name", collectionName);
      formData.append("model_type", title);
      formData.append("generator", generatorData.id);
      formData.append("file_type", selectedFileType);
      axios({
        method: "post",
        url: API_PATH,
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          Authorization: `Token ${appToken}`,
        },
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            resetForm();
            setProcessData(response.data);
            // window.location ="/collection-preview-page?id="+generatorData.id;
            navigation("/collection-preview-page", {
              state: {
                id: generatorData.id,
                name: generatorData.name,
                type: title,
                collection: response.data,
              },
            });
          } else {
            showErrorMessage(response.message);
          }
          //  <Navigate to="/collection-preview-page" state={response.data.id} />
        })
        .catch((err) => {
          if (err.response.data.message) {
            showErrorMessage(err.response.data.message);
          } else {
            showErrorMessage(err.message);
          }
        });
    } else {
      showErrorMessage("Please select a file to upload");
    }
  };

  return (
    <>
      <Sidenav
        modelTypesList={props.modelTypesList}
        selectedModelType={title}
      />
      <main className="main-content">
        <Header />
        {/* { showImageEditor && (<ImageEditorModal className="" closefn={setShowimageEditor} updateBlendingValues={updateBlendingImagePositioning} imageList={blendingSelectedObject}  bgImage={bgImageURL} imageOverlay={overLayImageState}/> )} */}
        {/* { showMediaGallery && (<MediaGalleryModel selectMedia={resetBlendedMediaData}  selectedMedia={blendingSelectedObject} closefn={setShowMediaGallery} />)} */}
        <div className="scrolldiv">
          <div className="generators">
            <div className="title-bar">
              <h2 className="title margin-auto">
                Upload Collection for&nbsp;
                <span
                  onClick={() => {
                    navigation("/collection-preview-page", {
                      state: location.state,
                    });
                  }}
                >
                  {generatorData.name}
                </span>
              </h2>
            </div>
            <div className="row">
              <div style={{ display: "flex", flexDirection: "column" }}>
                {uploadError && (
                  <div
                    data-show="true"
                    className="ant-alert ant-alert-error ant-alert-with-description ant-alert-no-icon error-bar"
                    role="alert"
                  >
                    <div className="ant-alert-content">
                      <div className="ant-alert-message">Error Message:</div>
                      <div className="ant-alert-description">
                        {errorMessage}
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        resetForm();
                      }}
                      type="button"
                      className="ant-alert-close-icon"
                      tabindex="0"
                    >
                      <span
                        role="img"
                        aria-label="close"
                        className="anticon anticon-close"
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="close"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                )}
                <div className="form-upload ">
                  {uploadInprogress && (
                    <section className="code-box-demo">
                      <div className="ant-spin-nested-loading">
                        <div>
                          <div
                            className="ant-spin ant-spin-spinning ant-spin-show-text"
                            aria-live="polite"
                            aria-busy="true"
                          >
                            <span className="ant-spin-dot ant-spin-dot-spin">
                              <i className="ant-spin-dot-item"></i>
                              <i className="ant-spin-dot-item"></i>
                              <i className="ant-spin-dot-item"></i>
                              <i className="ant-spin-dot-item"></i>
                            </span>
                            <div className="ant-spin-text">Processing...</div>
                          </div>
                        </div>
                        <div className="ant-spin-container ant-spin-blur">
                          <div
                            data-show="true"
                            className="ant-alert ant-alert-info ant-alert-with-description ant-alert-no-icon"
                            role="alert"
                          >
                            <div className="ant-alert-content">
                              <div className="ant-alert-message">
                                Alert message title
                              </div>
                              <div className="ant-alert-description">
                                Further details about the context of this alert.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                  {!uploadInprogress && (
                    <div className="upload-form-column">
                      <div className="item">
                        <div className="main">
                          <input
                            className="ant-input  ant-form-item-control"
                            id="collection"
                            maxLength="100"
                            onChange={(e) => setCollectionName(e.target.value)}
                            value={collectionName}
                            placeholder="Collection name"
                            type="text"
                          />
                        </div>
                        <div className="main">
                          <select
                            className="ant-col ant-form-item-control"
                            id="title"
                            value={title}
                            onChange={(e) => {
                              setModelTypeHandler(e.target.value);
                            }}
                          >
                            {modelTypes.map((type, key) => (
                              <option
                                name={selectedMediaType}
                                key={key}
                                value={type}
                              >
                                {type}
                              </option>
                            ))}
                          </select>
                        </div>
                        {fileName != "" && (
                          <div
                            className="ant-row ant-form-item file-name"
                            alt={fileName}
                          >
                            <div className="text">{fileName}</div>
                            {fileName.indexOf(".zip") > -1 && (
                              <BsFileEarmarkZip />
                            )}
                            {fileName.indexOf(".zip") == -1 && (
                              <BsFileEarmarkImage />
                            )}
                          </div>
                        )}
                      </div>
                      <div className="item">
                        <div className="upload-btn ant-col ant-form-item-control">
                          <input
                            className="uploadFileBtn"
                            type="file"
                            name="file1"
                            accept="image/*,.zip,.tar"
                            id="file"
                            onChange={(e) => resetUploadFile(e.target.files)}
                          />
                          <Button
                            icon={<UploadOutlined />}
                            className="ant-btn ant-btn-primary ant-btn-dangerous"
                          >
                            Select File
                          </Button>
                        </div>
                      </div>

                      <div className="item">
                        {fileName != "" && (
                          <div
                            className="ant-col ant-form-item-control"
                            onClick={() => {
                              processAttachedMedia();
                            }}
                          >
                            <Button
                              icon={<RadiusSettingOutlined />}
                              className="ant-btn-primary"
                            >
                              Start
                            </Button>
                          </div>
                        )}
                      </div>
                      {/* { showPickBlendingImage && (
                        <div className="image-selection-wrapper">
                          <div>Background</div>
                          <div>Overlay</div>
                          <div className="item" onClick={() => {
                            openMediaPickerBox(true, 'background');
                          }}>
                              { blendingSelectedObject[0].obj &&  blendingSelectedObject[0].obj.id != undefined &&
                              (<div className="img" style={{backgroundImage: "url(" + blendingSelectedObject[0].obj.blending_image_url+ ")"}}>
                              </div> ) }                             
                          </div>
                          <div className="item" onClick={() => {
                            openMediaPickerBox(true, 'overlay');
                          }}>{ blendingSelectedObject[1].obj &&  blendingSelectedObject[1].obj.id  != undefined &&
                            (<div className="img" style={{backgroundImage: "url(" + blendingSelectedObject[1].obj.blending_image_url+ ")"}}>
                            </div> ) }                                                                                                 
                          </div>
                          {blendingSelectedObject[1].obj  && blendingSelectedObject[0].obj && (
                             <div className="colspantwo">
                             <button className="tbn-media" onClick={()=>{ 
                              openImageEditor();
                             }}> Set Positioning</button>
                             </div>
                          )}                         
                        
                         </div>
                        // <button onClick={() =>{ 
                           
                        // }}>Pick Media</button>
                      )} */}
                    </div>
                  )}

                  {!uploadInprogress && (
                    <div>
                      <div className="bottom-action-nav">
                        <div className="left">
                          <Link to="/home">Generator</Link>
                          <BiChevronLeft />
                        </div>
                        <div className="right">
                          <Link
                            to="/collection-preview-page"
                            state={location.state}
                          >
                            Preview{" "}
                          </Link>
                          <BiChevronRight />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* <section className="code-box-demo">
                  <div className="site-card-wrapper processed-image-cards">
                    <div className="ant-row ant-card-panel">
                      {imageData.map((media) => (
                        <UploadImagePreview media={media} />
                      ))}
                    </div>
                  </div>
                </section> */}
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </main>
    </>
  );
}

export default Uploader;
