import React, { useState, useEffect } from "react";
import UserIcon from "./../assets/images/user-icon.png";
import authToken from "../hook/useAuthToken";
import { Link, useNavigate , Navigate } from "react-router-dom";
import API_PATH from './../config/config';

const Header = (props) => {
  const {appToken, resetToken} = authToken();
  const [isAuthenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {      
      const token = localStorage.getItem("token");
      setAuthenticated(token ? true : false);
    }
  }, []);

  const logout = () => {

    localStorage.removeItem("processingToken");  
      const res = fetch(`${API_PATH.USER_MANAGEMENT}/logout/`, {
        method: 'get',
        headers: {
          "Authorization": `Token ${appToken}`
        }
      })
      .then((response) => { 
        resetToken(null);
        setAuthenticated(false);
        return response.json() })
      .then((responseJson) => {
          localStorage.removeItem("processingToken");
          resetToken(null);
          setAuthenticated(false);
          <Navigate to="/login" />;       
      }).catch((error) => {
        localStorage.removeItem("processingToken");
          resetToken(null);
          setAuthenticated(false);
          <Navigate to="/login" />; 
      });

     
      // <Navigate to="/login" />;
    }

  return (
    <header className="main-header">
      <div className="d-flex fullwidth justify-content-between align-items-center">
        <a>Image Generator</a>

        <div className="user-profile">
          <div className="btn-outline user-title">
            <img src={UserIcon} />            
            My Account
            {/* <ul>
            <li onClick={logout} >
                <a onClick={logout} className="btn-outline">
                  Logout
                </a>
              </li>
              <li>
                <a className="btn-outline">My Collections</a>
              </li>
              
            </ul> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
