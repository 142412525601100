import React, { useState, useEffect, useRef } from "react";
import styles from "./../../assets/css/modals.css";
import { RiCloseLine } from "react-icons/ri";
import useImage from "use-image";
import { render } from "react-dom";
import CanvasPainting from "../CanvasPainting";

const bgValueMeters = {
  id: undefined,
  width: undefined,
  height: undefined,
  rotated: undefined,
};
const overLayValueMeters = {
  id: undefined,
  width: undefined,
  height: undefined,
  rotated: undefined,
  x: 0,
  y : 0
};

function resetImageMeters(obj, type) {
  const newWidth = obj.parent.children[2].children[0].attrs.width ? obj.parent.children[2].children[0].attrs.width : overLayValueMeters.width;
  const newHeight = obj.parent.children[2].children[0].attrs.height ? obj.parent.children[2].children[0].attrs.height : overLayValueMeters.height;
  const transformX = (obj.attrs["x"]) ? obj.attrs["x"] : 0;
  const transformY = (obj.attrs["x"]) ? obj.attrs["y"] : 0;  
  
  if (type === "bgImageBox") {
    bgValueMeters.width   = newWidth;
    bgValueMeters.height = newHeight
    bgValueMeters.rotation = obj.attrs["rotation"];        
  } else {
    overLayValueMeters.width = newWidth;
    overLayValueMeters.height = newHeight;
    overLayValueMeters.x = transformX - 25;
    overLayValueMeters.y = transformY - 25;
    overLayValueMeters.rotation = obj.attrs["rotation"];    
  }
}

const bgImageHeight = 400;
const bgImageWidth = 600;
let canvas;
let ctx;
var  flag = false,
w =0,
h = 0,
prevX = 0,
currX = 0,
prevY = 0,
currY = 0,
dot_flag = false;

var x = "black",
strokeSize = 12;
var canvasContainer;
const paintingCanvas = function () {
    
    canvas = document.getElementById('paintingCanvas');
    canvasContainer = document.getElementById('painting-container');
    ctx = canvas.getContext("2d");
    w = bgImageWidth;
    h = bgImageHeight;


    canvas.addEventListener("mousemove", function (e) {
        findxy('move', e)
    }, false);
    canvas.addEventListener("mousedown", function (e) {
        findxy('down', e)
    }, false);
    canvas.addEventListener("mouseup", function (e) {
        findxy('up', e)
    }, false);
    canvas.addEventListener("mouseout", function (e) {
        findxy('out', e)
    }, false);
}

function color(obj) {
  switch (obj.id) {
      case "green":
          x = "green";
          break;
      case "blue":
          x = "blue";
          break;
      case "red":
          x = "red";
          break;
      case "yellow":
          x = "yellow";
          break;
      case "orange":
          x = "orange";
          break;
      case "black":
          x = "black";
          break;
      case "white":
          x = "white";
          break;
  }
  if (x == "white") strokeSize = 14;
  else strokeSize = 2;

}

function draw() {  
    // ctx.beginPath();
    ctx.arc(currX - canvasContainer.getBoundingClientRect().left, currY - canvasContainer.getBoundingClientRect().top, 12, 0, 2 * Math.PI, false);
    ctx.fillStyle = x;
    ctx.fill();  
    ctx.closePath();
}

function erase() {
  // var m = confirm("Want to clear");
  // if (m) {
  //     ctx.clearRect(0, 0, w, h);
  //     document.getElementById("canvasimg").style.display = "none";
  // }
}

function save() {
  document.getElementById("canvasimg").style.border = "2px solid";
  var dataURL = canvas.toDataURL();
  document.getElementById("canvasimg").src = dataURL;
  document.getElementById("canvasimg").style.display = "inline";
}

function findxy(res, e) {
  if (res == 'down') {
      prevX = currX;
      prevY = currY;
      currX = e.clientX - canvas.offsetLeft;
      currY = e.clientY - canvas.offsetTop;

      flag = true;
      dot_flag = true;
      if (dot_flag) {
          ctx.beginPath();
          ctx.fillStyle = "red";
          ctx.fillRect(currX, currY, 2, 2);
          ctx.closePath();
          dot_flag = false;
      }
  }
  if (res == 'up' || res == "out") {
      flag = false;
  }
  if (res == 'move') {
      if (flag) {
          prevX = currX;
          prevY = currY;
          currX = e.clientX - canvas.offsetLeft;
          currY = e.clientY - canvas.offsetTop;
          draw();
      }
  }
}

var flag = false,
        prevX = 0,
        currX = 0,
        prevY = 0,
        currY = 0,
        dot_flag = false;

    var colorPaint = "black",
        widthStroke = 2;

const InPaintingToolModal = ({ closefn, imageList, updateInPaintingValues }) => {
  
  const [canvasWidth , setCanvasWidth ] =  useState(600);
  const [canvasHeight , setCanvasHeight] =  useState(300);
  const [ showModalData, setShowModalData ] = useState(false);
  useEffect(() => {
    
    console.log(imageList.originalWidth, imageList.originalHeight);
    console.log(imageList.originalWidth ,  window.innerWidth - 300);
    let bgVal ;
    if( imageList.originalWidth > window.innerWidth - 300 ){
      bgVal = calculateAspectRatioFit(
        imageList.originalWidth,
        imageList.originalHeight,
        window.innerWidth - 300,
        window.innerHeight - 300
      );
    } else {
       bgVal = {
        width : imageList.originalWidth,
        height : imageList.originalHeight,
      }
    }
    
    setCanvasWidth(bgVal.width);
    setCanvasHeight(bgVal.height);

    
    setShowModalData(true);
    setTimeout(() => {
      paintingCanvas();
     // var canvas = document.getElementById('paintingCanvas')
     
    }, 1000);
    
  }, [imageList]);
 
  const calculateAspectRatioFit = (
    srcWidth,
    srcHeight,
    maxWidth,
    maxHeight
  ) => {
    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);    
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  };

  const saveMask = () => {    
    var w = canvas.width;
	var h = canvas.height;

	var data;		

	const image = ctx.getImageData(0, 0, w, h);
    
    const  dataImg = image.data;
    const  length  = dataImg.length;

    for (let i = 0; i < dataImg.length; i += 4) { // red, green, blue, and alpha
        const r = dataImg[i + 0];
        const g = dataImg[i + 1];
        const b = dataImg[i + 2];
        const a = dataImg[i + 3];
        // console.log(r,g,b,a)
        if (r === 0 && g === 0 && b === 0 ) { // pixel is red
          dataImg[i + 0] = 255; // blue is set to 100%
          dataImg[i + 1] = 255; // green is set to 100%
          dataImg[i + 2] = 255; // blue is set to 100%          
            // resulting color is white
        }
    }

  //   for (let i = 0; i < dataImg.length; i += 4) { // red, green, blue, and alpha
  //     const r = dataImg[i + 0];
  //     const g = dataImg[i + 1];
  //     const b = dataImg[i + 2];
  //     const a = dataImg[i + 3];
  //     // console.log(r,g,b,a)
  //     if (r === 155 && g === 155 && b === 155 ) { // pixel is red
  //       dataImg[i + 0] = 0; // blue is set to 100%
  //       dataImg[i + 1] = 0; // green is set to 100%
  //       dataImg[i + 2] = 0; // blue is set to 100%          
  //       dataImg[i + 3] = 1000; // blue is set to 100%     
  //       console.log(a);
  //         // resulting color is white
  //     }
  // }

    ctx.putImageData(image, 0, 0);


    
    new Promise(function(resolve, reject) {
      resolve(canvas.toDataURL('image/png'))
    }).then(function(result) {
      
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      
      ctx.beginPath();
             
      var myImage = new Image();
      myImage.src = result;
      myImage.onload = function() {
         ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        ctx.fillRect(0,0,w,h);        
        ctx.drawImage(myImage, 0, 0);
        // document.getElementById("testImg").src = canvas.toDataURL('image/png');
         const maskdata = { 
          "json_data" : {
            "image_id": imageList.id,
            "mask_data" : canvas.toDataURL('image/png')
          }
        }
        updateInPaintingValues(maskdata);
      }      
     });

     

    // draw().then(function(result) {
    //   console.log("draw completed ");
    //   document.getElementById("testImg").src = result;
    //   // updateInPaintingValues(maskdata);
    // })
	
    // const maskDetail = canvas.toDataURL('image/png');
    // const maskdata = { 
    //   "json_data" : {
    //     "image_id": imageList.id,
    //     "mask_data" : maskDetail
    //   }
    // }   
    
  }
  return (
    <div>
      { showModalData && (
     <div>
     <div>{styles.darkBG}</div>
     <div
       className="darkBG modal-preview-bg inpaintingbg"
       onClick={() => closefn(false)}
     />
     <div className="centered modal-preview in-painting-modal">
       <div className="modal">
         <div className="modalHeader">
         <div className="modal-top-action-btn ">
           <button id="saveMaskBtn" onClick={() => {
              saveMask();
             }}>Apply Mask</button>
           </div>
           <h5 className="modal-heading">Image Editor</h5>
         </div>
         <button className="closeBtn" onClick={() => closefn(false)}>
           <RiCloseLine style={{ marginBottom: "-3px" }} />
         </button>
         <div className="modalContent relative painting-container" id="painting-container"
          style={{ height: canvasHeight, width: canvasWidth }}
         >
          {/* <CanvasPainting></CanvasPainting> */}
         <canvas width={canvasWidth - 42 } height={canvasHeight - 29} style={{'border':'1px solid #cccccc', 'margin-left': '20px','margin-top': '10px'}}id="paintingCanvas" > </canvas>
           <div className="modal center bgImageDiv" style={{ height : canvasHeight, width : canvasWidth , margin : 'auto' }}>
            <img src={imageList.input_url} />
           </div>
         </div>
         </div>
         </div>
         <script>
          
         </script>
         </div>            
      )}
    </div>
  );
};

export default InPaintingToolModal;
