import React,  { useState} from "react";
import { CgMoveDown } from 'react-icons/cg';

export default function UploadImagePreview(props) {
    const imageData = props.media;
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [selectedImagePath, setSelectedImagePath] = useState('');
    const [selectedImagePathOutPut, setSelectedImagePathOutPut] = useState('');
    const closePreviewModel = () => {
        setShowPreviewModal(false);
    }

    const loadImage = (path, path2) => {
        setSelectedImagePath(path);
        setSelectedImagePathOutPut(path2);
        setShowPreviewModal(true);
    }
  return (
    <>
     { imageData && (       
        <div className="ant-card ">
            <div className="ant-header">
               <div className="text  text-truncate">Image: {imageData.name}</div>
            </div>
            <div className="ant-content">
                <div className="link" 
                 style={{backgroundImage: "url(" + (imageData.input_thumbnail_url ? imageData.input_thumbnail_url : imageData.input_url)  + ")"}} 
                onClick= {() =>{
                        props.loadImagePreview(imageData.input_url, imageData.output_url, props.media);
                }} >
                </div>
                <div className='convert-icon'><CgMoveDown /></div>
                <div  className="link bottom-img" 
                style={{backgroundImage: "url(" + (imageData.output_thumbnail_url ? imageData.output_thumbnail_url : imageData.output_url)  + ")"}} 
                onClick= {() =>{
                        props.loadImagePreview(imageData.input_url, imageData.output_url, props.media);
                }}>
                </div>
            </div>    
    </div>    
     )}
     </>
        
  )
}

