import React, { useState, useEffect } from "react";
import useMediaGalleryState from '../hook/useMediaGalleryState';
import useMediaEditToolState from '../hook/useMediaEditToolState';
import useInPaintingToolState from '../hook/useInPaintingToolState';
import useUpdateCollectionDrawer from '../hook/useUpdateCollectionDrawer';
import ImageEditorModal from "./modals/ImageEditorModal";
import MediaGalleryModel from "./modals/MediaGalleryModel";
import { useBetween } from "use-between";
import CreateCollectionDrawer from "./UploadFormDrawer";
import InPaintingToolModal from "./modals/InPaintingToolModal";
import { useSelector, useDispatch } from "react-redux";

const SharedComponent = (props) => {
  const toggleCollectionDrawer = useSelector((state) => state.settings.toggleCollectionDrawer);  
  const { mediaModalVisibility, showMediaPicker, setSelectedMedia, triggerCallBack, setMediaModelVisibility } = useBetween(useMediaGalleryState);
  const { showMediaEditor, triggerMediaEditorCallBack, meditEditorSelectedObject, setShowMediaEditor } = useBetween(useMediaEditToolState);
  const {  showInPaintingModal, callBackInPainting, triggerInPaintingCallBack, setShowInPaintingModal, inPaintingMediaObj } = useBetween(useInPaintingToolState);
  const { showDrawer, setShowDrawer } = useBetween(useUpdateCollectionDrawer);
  const [isAuthenticated, setAuthenticated] = useState(false);
  return (
    <div className="sharedComponent">        
      { mediaModalVisibility && (
        <MediaGalleryModel
          selectMedia={triggerCallBack}          
          closefn={setMediaModelVisibility}
        />              
      )}
      { showMediaEditor && (<ImageEditorModal className=""
      closefn={setShowMediaEditor}
      updateBlendingValues={triggerMediaEditorCallBack}
      imageList={meditEditorSelectedObject} /> )}
      
      { showInPaintingModal && (<InPaintingToolModal className=""
      closefn={setShowInPaintingModal}
      updateInPaintingValues={triggerInPaintingCallBack}
      imageList={inPaintingMediaObj} /> )}
      { showInPaintingModal && ( <div>Show in paiting model</div>)}
      <CreateCollectionDrawer modelTypesList={props.modelTypesList} show={toggleCollectionDrawer}  setShow={setShowDrawer}></CreateCollectionDrawer>
    </div>
  );
};

export default SharedComponent;
