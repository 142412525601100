import React, { useState, useEffect } from "react";
import styles from "./../../assets/css/modals.css";
import { RiCloseLine } from "react-icons/ri";
import { CgMoveDown } from 'react-icons/cg';
import EpiCycleProcessing from "./../EpiCycleProcessing";
import { BiListUl } from "react-icons/bi";



var isCreateArt = false;
const EpicyclePreviewModal = ({ closefn, jsonPath , media }) => {  
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [epiData, setEpiData] = useState(null)
    const [artData, setArtData] = useState(media);
    
    useEffect(() => {
        if(jsonPath != '') {            
            fetch(jsonPath)
            .then((response) => response.json())
            .then((data) => {                
                setIsDataLoaded(true);
                isCreateArt = true;
                setEpiData(data);
              // jsonData is parsed json object received from url  
            //   setIsDataLoaded(true);            
            //   setProcessingData(data);
            //   isCreateArt = true;
            //   setIsArt(false);
            })
            .catch((error) => {
              // handle your errors here
              console.error(error);
            });
        }
    }, jsonPath);
  return (
    <>
    <div >{styles.darkBG}</div>
      <div className="darkBG modal-preview-bg" onClick={() => closefn(false)} />
      <div className="centered modal-preview modal-preview-epicycle epi-cycle-modal-animation">
      <div className={["info", "iconTop"].join(' ')}><BiListUl style={{ marginBottom: "-3px" }} /></div>
        <div className="modal">
          {/* <div className="modalHeader">
            <h5 className="modal-heading">Preview</h5>
          </div> */}
          <button className="closeBtn" onClick={() => closefn(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
          { isDataLoaded && (
          <EpiCycleProcessing data={epiData} artData={artData} title="Sharjeel is here " className="epi-cycle-modal-animation">
                          </EpiCycleProcessing>          
          )}
          </div>                    
        </div>
      </div>
    </>
  );
};
 
export default EpicyclePreviewModal;