import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Sidenav from "../../components/Sidenav";
import { Collapse } from "antd";
import axios from "axios";
import { Spin } from "antd";
import authToken from "../../hook/useAuthToken";
import API_PATH from "../../config/config";
import {
  useSearchParams,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import UploadImagePreview from "../../components/UploadImagePreview";
import ImageDifferenceModal from "../../components/modals/ImageDifferenceModal";
import { BiImageAdd, BiEdit, BiRefresh } from "react-icons/bi";
import EpicyclePreviewModal from "../../components/modals/EpicyclePreviewModal";
import SharedComponent from "../../components/SharedComponent";
import useUpdateCollectionDrawer from "../../hook/useUpdateCollectionDrawer";
import { useBetween } from "use-between";
import { useSelector, useDispatch } from "react-redux";
import BlendedImageSegmentationCard from "../../components/BlendedImageSegmentationCard";
import drawerPreviewAction from "./../../state/actions/collectionActions";
import settingActions from "../../state/actions/settingActions";
import DrawerPreviewComponent from "../../components/DrawerPreviewComponent";

var isCreateArt = false;

function CollectionsPreviewPage(props) {
  const collectionState = useSelector((state) => state.collection);
  const drawerPreviewData = useSelector((state) => state.drawerPreview);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const {
    showDrawer,
    setShowDrawer,
    toggleDrawer,
    setStateObj,
    setCollectionFieldName,
    reloadCollectionData,
  } = useBetween(useUpdateCollectionDrawer);
  let collectionGenKey = 0;
  const [searchParams] = useSearchParams();
  if (!location.state || location.state.id == undefined) {
    window.location = "/";
  }
  let generatorId = location.state.id;
  const generatorName = location.state.name;
  if (!location.state) {
    generatorId = searchParams.get("id");
  }

  useEffect(() => {
    // Reload Collection for force reload after edit.
    if (collectionState.reload) {
      loadCollectionData(selectedCollectionName);
    }
  }, [collectionState.reload]);

  const { appToken } = authToken();
  const [loading, setLoading] = useState(true);
  const [authorData, setAuthorData] = useState([]);
  const [generatorData, setGeneratorData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [selectedCollectionName, setSelectedCollectionName] = useState([]);
  const [selectedCollectionData, setSelectedCollectionData] = useState("");
  const [collectionLoaded, setCollectionLoaded] = useState(false);
  const [selectedMediaType, setSelectedMediaType] = useState("");
  const [selectedMediaTypeKey, setSelectedMediaTypeKey] = useState(0);
  const [showEpicycleModal, setShowEpicycleModal] = useState(false);
  const [selectedCollectedMedia, setSelectedCollectedMedia] = useState("");

  // Image Preview Dialog
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedImagePath, setSelectedImagePath] = useState("");
  const [selectedImagePathOutPut, setSelectedImagePathOutPut] = useState("");
  const closePreviewModel = () => {
    setShowPreviewModal(false);
  };

  const closeEpiCyclePreview = () => {
    setShowEpicycleModal(false);
  };
  const loadImage = (path, path2, media) => {
    setSelectedCollectedMedia(media);
    if (selectedMediaType === "EPICYCLES") {
      setShowEpicycleModal(true);
    } else {
      setSelectedImagePath(path);
      setSelectedImagePathOutPut(path2);
      setShowPreviewModal(true);
    }
  };

  let generatorTabKeys = 0;
  let collectionKeys = 0;
  const { Panel } = Collapse;
  useEffect(() => {
    if (appToken) {
      getAuthorsData();
    }
  }, [appToken]);

  const getAuthorsData = () => {
    setCollectionLoaded(false);
    setLoading(true);
    axios({
      method: "GET",
      url: `${API_PATH.COLLECTION}/?generator_id=${generatorId}`,
      headers: {
        Authorization: `Token ${appToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setAuthorData(res.data);
          setGeneratorData((generatorData) => [null]);
          const genratorList = [];
          res.data.job_type.forEach((type) => {
            genratorList.push({
              name: type,
              id: generatorTabKeys,
              data: res.data[type],
            });
            generatorTabKeys++;
          });
          setGeneratorData((generatorData) => [...genratorList]);
          let firstTypeeId = res.data[res.data.job_type[0]];
          if (res.data.job_type.length > 0) {
            if (location.state && location.state.collection != undefined) {
              firstTypeeId = res.data[location.state.type];
              if (firstTypeeId == undefined) {
                setSelectedMediaType(res.data.job_type[0]);
                loadCollectionData(firstTypeeId[0]);
              } else {
                setSelectedMediaType(location.state.type);
                setSelectedMediaTypeKey(
                  res.data.job_type.indexOf(location.state.type)
                );
                const selectedCat = res.data[location.state.type].filter(
                  (item) => item.id === location.state.collection.id
                );
                firstTypeeId = selectedCat[0];
                loadCollectionData(firstTypeeId);
              }
            } else {
              setSelectedMediaType(res.data.job_type[0]);
              loadCollectionData(firstTypeeId[0]);
            }
          }
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const loadCollectionData = (collection) => {
    setLoading(true);
    setSelectedCollectionName(collection);
    setCollectionData((collectionData) => [null]);
    setSelectedCollectionData(collection.id);
    axios({
      method: "GET",
      url: `${API_PATH.GAN_IMAGE_MODELS}/images/?collection_id=${collection.id}`,
      headers: {
        Authorization: `Token ${appToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setCollectionData((collectionData) => [...[res.data][0]]);
          setCollectionLoaded(true);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const resetItemVal = (media) => {
    var collectionChanged = collectionData.filter(
      (item) => item.id == media.id
    );
    if (collectionChanged.length > 0) {
      collectionChanged[0].output_thumbnail_url = media.output_thumbnail_url;
      collectionChanged[0].output_url = media.output_url;
    }
  };

  const updateGeneratorCollection = (generator) => {
    setCollectionFieldName(selectedCollectionName.name);
    dispatch(settingActions.setCollectionDrawerObject(generator));
    dispatch(settingActions.toggleModifyCollectionDrawer(true));
    setStateObj(generator);
    // toggleDrawer(true);
    // setShowDrawer(true);
  };

  const getModelTypeName = (model) => {
    const index = collectionState.model_types.indexOf(model);
    if (index > -1 && collectionState.model_types_names.length >= index) {
      return collectionState.model_types_names[index];
    } else {
      return model;
    }
  };
  return (
    <>
      <main className="main-content">
        <SharedComponent
          title="test"
          modelTypesList={props.modelTypesList}
        ></SharedComponent>
        {showPreviewModal && (
          <ImageDifferenceModal
            closefn={closePreviewModel}
            media={selectedCollectedMedia}
            selectedMediaType={selectedMediaType}
            inputImage={selectedImagePath}
            outPutImage={selectedImagePathOutPut}
            setIsOpen={showPreviewModal}
            resetItemVal={resetItemVal}
          />
        )}
        {showEpicycleModal && (
          <EpicyclePreviewModal
            jsonPath={selectedCollectedMedia.epicycle_json_url}
            closefn={closeEpiCyclePreview}
            media={selectedCollectedMedia}
          />
        )}
        <div className="scrolldiv relative">
          <div className="heading-selected-generator">
            <h3>
              Generator: <span>{generatorName}</span> Type :{" "}
              <span>{selectedMediaType}</span> Collection :{" "}
              <span>{selectedCollectionName.name} </span>{" "}
            </h3>
          </div>
          <div className="d-grid generate ">
            <div className="tabs">
              {/* {generatorData.length > 0 && ( */}
              <div>
                <div className="button-collection">
                  <div
                    onClick={() => {
                      updateGeneratorCollection({
                        name: generatorName,
                        id: generatorId,
                        type: selectedMediaType,
                      });
                    }}
                  >
                    {" "}
                    Add <BiImageAdd />
                  </div>
                  <div
                    onClick={() => {
                      updateGeneratorCollection({
                        name: generatorName,
                        id: generatorId,
                        collection: selectedCollectionName,
                        type: selectedMediaType,
                      });
                    }}
                  >
                    {" "}
                    Edit Collection <BiEdit />
                  </div>
                </div>
                {generatorData.map((type, key) => (
                  <Collapse
                    ghost
                    key={key}
                    defaultActiveKey={[selectedMediaTypeKey]}
                  >
                    {type.id != undefined && (
                      <Panel header={getModelTypeName(type.name)} key={key}>
                        <div className="author-list">
                          {type.data &&
                            type.data.map((collection, i) => (
                              <div
                                id={collectionGenKey}
                                key={i}
                                className={
                                  selectedCollectionData === collection.id
                                    ? "actiive-author-name author-name-list"
                                    : "author-name author-name-list"
                                }
                                onClick={() => {
                                  setSelectedMediaType(type.name);
                                  loadCollectionData(collection);
                                }}
                              >
                                <div className="title Test_Gen_Faizan">
                                  {collection.name}
                                </div>
                                <div className="status">
                                  {collection.status &&
                                  collection.status.toLowerCase() != "completed"
                                    ? collection.status
                                    : ""}
                                </div>
                              </div>
                            ))}
                        </div>
                      </Panel>
                    )}
                  </Collapse>
                ))}
              </div>
              {/* )} */}
            </div>

            <div
              className={[
                selectedMediaType == "SEGMENTATION"
                  ? "segmentation-wraper"
                  : "",
                "art-results",
              ].join(" ")}
            >
              <Spin className="h-100" tip="Loading..." spinning={loading}>
                <div className="row">
                  <div className="col-md-12 gallery-container">
                    <div className="site-card-wrapper processed-image-cards">
                      <div
                        className={[
                          selectedMediaType == "SEGMENTATION"
                            ? "segmentation-records"
                            : "",
                          "ant-row ant-card-panel",
                        ].join(" ")}
                      >
                        {collectionData.map((media, index) =>
                          selectedMediaType != "SEGMENTATION" ? (
                            <UploadImagePreview
                              loadImagePreview={loadImage}
                              title={media}
                              type={selectedMediaType}
                              media={media}
                            />
                          ) : (
                            <BlendedImageSegmentationCard
                              token={appToken}
                              type={selectedMediaType}
                              media={media}
                              index={index}
                            />
                          )
                        )}

                        {!loading &&
                          (collectionData == undefined ||
                            collectionData.length == 0) && (
                            <div className="loading-collection">
                              Still under processing, check back soon.{" "}
                              <div
                                className="link-text yellow"
                                onClick={() => {
                                  setSelectedMediaType(
                                    selectedCollectionName.name
                                  );
                                  loadCollectionData(selectedCollectionName);
                                }}
                              >
                                <BiRefresh></BiRefresh>Refresh
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </Spin>
              {selectedMediaType == "SEGMENTATION" && (
                <DrawerPreviewComponent />
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default CollectionsPreviewPage;
