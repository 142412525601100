import { useState, useEffect } from "react";

const useUpdateCollectionDrawer = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [ stateObj , setStateObj ] = useState(null);
  const [ collectionFieldName , setCollectionFieldName ] = useState("");
  const [ callBackReload , setCallBackReload ] = useState();
  const toggleDrawer = (val) => {
    setShowDrawer(val);
  }

  return { showDrawer, setShowDrawer, toggleDrawer, stateObj , setStateObj, collectionFieldName, setCollectionFieldName };
};

export default useUpdateCollectionDrawer;
