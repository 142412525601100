import React from "react";
import styles from "./../../assets/css/modals.css";
import { RiCloseCircleLine } from "react-icons/ri";
import { BiListUl } from "react-icons/bi";
import { Link } from "react-router-dom";

const CollectionListModel = ({ showModal, list, title }) => {
  console.log(list);
  return (
    <>
      <div>{styles.darkBG}</div>
      <div
        className="darkBG modal-preview-bg"
        onClick={() => showModal(false)}
      />
      <div className="centered modal-confirmation info-modal list-modal fadein-animation">
        <div className={["info", "iconTop"].join(" ")}>
          <BiListUl style={{ marginBottom: "-3px" }} />
        </div>
        <div className="modal">
          <div className="modalHeader">
            <h5 className="modal-heading">{title}</h5>
          </div>
          <button className="closeBtn" onClick={() => showModal(false)}>
            <RiCloseCircleLine />
          </button>
          <div className="modalContent">
            <div className="center">
              <div className="list-center two-in-row">
                {list.collections.map((item, index) => {
                  return (
                    <div className="collectionlist">
                      <div className="index"> {++index})</div>
                      <div className="text-truncate">
                        <Link
                          to="/collection-preview-page"
                          state={{
                            id: list.id,
                            name: list.name,
                            collection: item,
                            type: item.model_type,
                          }}
                          className="btn-eye "
                        >
                          {item.name}
                        </Link>
                      </div>
                      <div className="img-count">{item.images_count}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionListModel;
