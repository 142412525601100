import { useState, useEffect } from "react";
import API_PATH from "./../config/config";
import axios from "axios";
import authToken from "./../hook/useAuthToken";
import { useSelector , useDispatch} from "react-redux";
// import { useDispatch } from "react-redux";
import generatorActions from "./../state/actions/index";

const useGeneratorState = () => {
  const dispatch = useDispatch();
  const generatorState = useSelector(state => state.generator);
  
  let listNew = [];
  const { appToken } = authToken();
  const [ modelType, setModelType] = useState('');
  const [ titleGenerator, setTitleGenerator ] = useState('Generators of all models.');
  const [listGenerator, setListGenerator] = useState([]);
  const [alreadyLoading, setAlreadyLoading] = useState(false);
  const [generatorDataLoading, setGeneratorDataLoading] = useState(false);    
  const GeneratorHeading = { 
    EPICYCLES : "Epicycles",
    DAY2NIGHT : "Day to Night",
    NIGHT2DAY : "Night to Day",
    SUMMER2WINTER : "Summer to Winter",
    WINTER2SUMMER : "Winter to Summer",
    BLENDING : "Blending",
    INPAINTING : "In Painting",
    HOME : "Generators of all models.",
  }

  useEffect(() => {
    setAlreadyLoading(false);        
    loadGeneratorData(true);
  }, [window.location.search]);

  const loadGeneratorData = (forceReload) => {
    if(forceReload){
      setAlreadyLoading(false);
    }
    if (appToken) {
      let  searchParams = ""; 
      let searchModal = "";
              
      const showEpicycles = ( window.location.search.indexOf("name=") > - 1 )  ? true : false;      
      let queryURL = searchModal != '' ? "?model_type="+searchModal : "";

        if( window.location.search != ''  && window.location.search.split("name=").length > 0){
          searchParams =  GeneratorHeading[window.location.search.split("name=")[1].toUpperCase()];        
          setTitleGenerator(searchParams);
          searchModal = window.location.search.split("name=")[1];
        } else {
          resetGeneratorHeading();
        } 
        queryURL = searchModal != '' ? "?model_type="+searchModal : ""; 
        setTimeout(function(){          
          if(appToken != '' && appToken != null && appToken != undefined &&  (!alreadyLoading || forceReload) ) {
            setAlreadyLoading(true);
            setGeneratorDataLoading(true);
          setListGenerator([]);
          axios({
            method: "GET",
            url: `${API_PATH.GENERATOR}${queryURL}`,
            headers: {
              Authorization: `Token ${appToken}`,
            },
          })
            .then((res) => {
              if (res.data !== undefined && res.data.length > 0) {
                loadListGenerator(res.data);
                setGeneratorDataLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          }
        }, 100);                 
    } else {
      setAlreadyLoading(false);
      resetGeneratorHeading();
    }
 
  }

  useEffect(() => {       
   if(!appToken || appToken != undefined){
    setAlreadyLoading(false);        
   loadGeneratorData();   
   }
   
  }, [appToken]);

  useEffect(() => {

  }, [setListGenerator])
  const removeGenerator = (generator, index, callback) => {
    
    const data = {
      generator_id: generator,
    };
    
    axios({
      method: "DELETE",
      url: `${API_PATH.GENERATOR}?generator_id=${generator}`,
      headers: {        
        Authorization: `Token ${appToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        
        
        if (res.data) {
          removeGeneratorFromList(generator);
          // setListGenerator(listGenerator.filter(item => item.id !== generator));
        }
        callback(res.data);
      })
      .catch((err) => {
        console.log(err);
        callback(err.response);
      });
  }



  const creatNewGenerator = (name, callback) => {
    const data = {
      name: name,
    };
    
    axios({
      method: "POST",
      url: `${API_PATH.GENERATOR}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${appToken}`,
      },
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((res) => {
        if (res.data) {
          addGenerator(res.data);
        }
        callback(res.data);
      })
      .catch((err) => {
        console.log(err);
        callback(err.response);
      });
  };
  const addGenerator = (data) => {    
    const datanew = [{
      id : data.id, name: data.name, created_at : data.created_at , images_count : 0, collections: [], model_types_gen : [] }];
      setNewGeneratorInList(datanew);
  };


  const loadListGenerator = (data) => {
    dispatch(generatorActions.loadGenertator(data));
  }
  const resetGeneratorHeading = () => {
    setTitleGenerator(GeneratorHeading["HOME"]);
  }
  const setNewGeneratorInList = (newVal) => {
    dispatch(generatorActions.addGenertator(newVal));            
  }
  const removeGeneratorFromList = (index) => {
    console.log("remove Generator");
    dispatch(generatorActions.removeGnerator(index));            
  }

  return { listGenerator, addGenerator, creatNewGenerator, removeGenerator, setModelType, titleGenerator, resetGeneratorHeading, generatorDataLoading };
};

export default useGeneratorState;
