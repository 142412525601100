import { useState , useEffect} from "react";
import axios from "axios";
import API_URL from "./../config/config";
const useAuthToken = () => {
    const [appToken, setAppToken] = useState(null);
    const [loadingToken, setLoadingToken] = useState(false);
    const [modelTypes, setModelTypes] = useState(null);
    const [modelTypesName, setModelTypesName] = useState(null);
    useEffect(() => {      
      let authToken = localStorage.getItem("processingToken");        
      if( authToken != undefined && authToken && authToken != '' ){
        if (!appToken ){
          if( JSON.parse(authToken) && JSON.parse(authToken).token){         
            authToken = JSON.parse(authToken).token;                                  
            setAppToken(authToken);                                     
          }
        }
      } else {
        localStorage.removeItem("processingToken");        
        if( window.location.href.indexOf("/login") == -1 ){
          window.location = "/login";
        }
      }   

    }, [localStorage.getItem("processingToken")]);
    const resetToken = (data) => {      
      if(data){
        localStorage.setItem("processingToken", JSON.stringify({ user : data.user, token : data.token }));
        setAppToken(data.token);
      } else {
        localStorage.removeItem("processingToken");
        setAppToken(null);
      }
    }
    
  useEffect(() => {    
  }, [modelTypes])
  
    return {appToken, setAppToken, resetToken};
}

export default useAuthToken;