import { useState, useEffect } from "react";
import API_PATH from "../config/config";
import axios from "axios";
import authToken from "./useAuthToken";

const useMediaGalleryState = () => {
  const [showInPaintingModal, setShowInPaintingModal] = useState(false);
  const [callBackInPainting, setCallBackInPainting] = useState(
    () => {}
  );
  
  const [inPaintingMediaObj, setInPaintingMediaObj] = useState();

  const displayInPaintingTool = (callback, obj) => {     
    setShowInPaintingModal(true);
    setInPaintingMediaObj(obj);    
    setCallBackInPainting((callBackInPainting) => [
      callBackInPainting,
      callback,
    ]);
  };

  const triggerInPaintingCallBack = (obj) => {
    if (
      callBackInPainting != undefined &&
      callBackInPainting.length > 0
    ) {
      callBackInPainting[1](obj);
      setShowInPaintingModal(false);      
    }
  };

  // useEffect(() => {
  //   console.log(" Local changed ... ", mediaModalVisibility);
  //   setShowMediaPicker(mediaModalVisibility);
  // }, [mediaModalVisibility]);

  return {
    displayInPaintingTool,
    showInPaintingModal,
    setShowInPaintingModal,
    triggerInPaintingCallBack,
    inPaintingMediaObj
  };
};

export default useMediaGalleryState;
