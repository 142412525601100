import React from "react";
import axios from "axios";
import { ReactP5Wrapper } from "react-p5-wrapper";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Spin, Badge } from "antd";

import { sketch, drawSketch, handleP5Events } from "../lib/p5js.js";
// import  {sketch, drawSketch} from "./../lib/p5js-o";

var t = 0;
var tail = [];
var a;
var f1;
var f2;
var pGlobal;
var isCreateArt = false;

export default function EpiCycleProcessing(props) {
  const data = props.data;
  const artData = props.artData;

  if (data) {
    setTimeout(function () {
      drawSketch(data);
      handleP5Events("resume");
      isCreateArt = true;
    }, 50);
  }
  return (
    <>
      {artData && (
        <div className="col-md-12 epicycle-view-grid">
          <div className="col-md-12 image-comparison">
            <div className="item">
              <div className="image-container original-img">
              <Badge.Ribbon text="Original Image" status="success" />
                <TransformWrapper>
                  <TransformComponent>
                    <img src={artData.input_url} alt="" />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            </div>
            <div className="item">
            <Badge.Ribbon text="Edge Image" status="success" />
              <div className="image-container original-img">
                <TransformWrapper>
                  <TransformComponent>
                    <img src={artData.output_url} alt="" />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            </div>
            {/* <div className="input-images">
                            <div className="input-container">
                              <div className="input-images-title">
                                Original Image 
                              </div>
                              <div className="image-container original-img">
                                {/* <TransformWrapper>
                                  <TransformComponent> */}
            {/* <img src={artData.input_url} alt="" /> */}
            {/* </TransformComponent>
                                </TransformWrapper> */}
            {/* </div>
                            </div>
                          </div>
                          <div className="input-images">
                            <div className="input-container">
                              <div className="input-images-title">
                                Edge Image
                              </div>
                              <div className="image-container"> */}
            {/* <TransformWrapper>
                                  <TransformComponent> */}
            {/* <img src={artData.output_url} alt="" /> */}
            {/* </TransformComponent>
                                </TransformWrapper> */}
            {/* </div>
                            </div>
                          </div> */}
          </div>

          <div className="col-md-12">
            {/* <div className="btn-process-container">
                        <button className="btn-stop-process" onClick={() =>{
                          handleP5Events("stop");
                        }}> Stop </button>
                        <button className="btn-stop-process" onClick={() =>{
                          handleP5Events("resume");
                        }}> Resume </button>
                        </div> */}

            <div className="sketch canvas-sketch">              
              <ReactP5Wrapper sketch={sketch} width="600" height="600" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
