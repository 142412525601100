import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import API_PATH from "./../config/config";
import axios from "axios";
import authToken from "./../hook/useAuthToken";
import "./../assets/css/template.css";

import {
  faCheck,
  faTimes,
  faInfoCircle,
  faHospitalUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MediaGallery = ({selectMedia, selectedMedia}) => {
    const { appToken } = authToken();
  const [limit, setlimit] = useState(30);
  const [offset, setOffset] = useState(0);
  const [mediaList, setMediaList] = useState([]);

  useEffect(() => {   
    const apiPAth = API_PATH.BLEND_OBJECT;
    // const apiPAth = "/gallery/gallery.json";
    if( appToken != undefined ) {
        axios({
            method: "GET",
            url: `${apiPAth}`,
            headers: {
              Authorization: `Token ${appToken}`,
              'Content-Type': 'application/json'
            }, 
            body: JSON.stringify({limit : limit, offset : offset}),
          })
            .then((res) => {
              if (res.data !== undefined && res.data.length > 0) {
                  setMediaList((mediaList) => [...mediaList, ...res.data]);
              }
            })
            .catch((err) => {
              console.log(err);
            });
    }
  
  }, [appToken, limit, offset]);

  return (
    <>
      <div className="gallery-wrapper ">        
            {
                mediaList.map( (item, index) => (
                    <div className="item" style={{backgroundImage: "url(" + item.blending_image_url  + ")"}} onClick={() => {
                        selectMedia(item);
                    }}>
                        {/* <img src={item.blending_image_url} /> */}
                    </div>
                    )
                )}                  
      </div>
    </>
  );
};

export default MediaGallery;
