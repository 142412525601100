const API_PATH = {
  USER_MANAGEMENT: "/api/user_management",
  IMAGE_UPLOAD_URL: "/api/image_upload",
  GENERATOR: "/api/gan_image_models/generators/",
  COLLECTION: "/api/gan_image_models/collections/",
  GAN_IMAGE_MODELS: "/api/gan_image_models/",
  BLEND_IMAGE: "/api/gan_image_models/blend_images/",
  BLEND_OBJECT: "/api/gan_image_models/blending_objects/",
  INPAINT_IMAGE_API: "/api/gan_image_models/inpaint_images/",
  MODEL_TYPES: "api/gan_image_models/model_types/",
  GAN_IMAGE_MODELS: "api/gan_image_models/",
};

export const REGEX = {
  USER_REGEX: /^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*$/,
  EMAIL_REGEX:
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  PWD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/,
};
export default API_PATH;
