const addGenertator = (generatorData) => {
    return (dispatch) => {
        dispatch( {
            type : 'addnew',
            payload: generatorData
        })
    }
}

const removeGnerator = (index) => {
    return (dispatch) => {
        dispatch( {
            type : 'removeGenerator',
            payload: index
        })
    }
}

const loadGenertator = (generatorData) => {
    return (dispatch) => {
        dispatch( {
            type : 'load',
            payload: generatorData
        })
    }
}

export default { addGenertator, removeGnerator, loadGenertator};