import React, { useState, useEffect } from "react";
import { OBJModel } from "react-3d-viewer";
import { Button, Form, Popconfirm, Col, Select, Row, Tag } from "antd";

import API_PATH from "../config/config";
import axios from "axios";
import { Spin } from "antd";
import authToken from "../hook/useAuthToken";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
const Mesh = () => {
  const { appToken } = authToken();
  const [loading, setLoading] = useState(false);
  const [meshes, setMeshes] = useState([]);
  const [singleMeshes, setSingleMeshes] = useState([]);
  const [active, setActive] = useState(null);

  useEffect(() => {
    if (appToken) {
      getAllMeshes(appToken);
    }
  }, [appToken]);

  const getAllMeshes = (appToken) => {
    setLoading(true);

    axios({
      method: "GET",
      url: `${API_PATH.GAN_IMAGE_MODELS}mesh_request/`,
      headers: {
        Authorization: `Token ${appToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          //console.log("list mi", res.data);
          setMeshes(res.data);
          if (res.data.length > 0) {
            viewSingleMesh(res.data[0].id);
          }
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  const viewSingleMesh = (id) => {
    setLoading(true);
    setActive(id);
    console.log("id", id);
    setSingleMeshes([]);
    axios({
      method: "GET",
      url: `${API_PATH.GAN_IMAGE_MODELS}meshes/`,
      headers: {
        Authorization: `Token ${appToken}`,
      },
      params: {
        mesh_request_id: id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log("res", res.data);
          setSingleMeshes(res.data);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  const deleteSingleMesh = (id) => {
    setLoading(true);
    setSingleMeshes([]);
    axios({
      method: "DELETE",
      url: `${API_PATH.GAN_IMAGE_MODELS}mesh_request/?mesh_request_id=${id}`,
      headers: {
        Authorization: `Token ${appToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          getAllMeshes(appToken);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    const id = values.classes.value.split(",")[1].trim();
    let formData = new FormData();
    formData.append("name", values.classes.label);
    formData.append("class_id", id);
    setLoading(true);
    axios({
      method: "POST",
      url: `${API_PATH.GAN_IMAGE_MODELS}mesh_request/`,
      headers: {
        Authorization: `Token ${appToken}`,
      },
      data: formData,
    })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          console.log("response", res.data);
          getAllMeshes(appToken);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <main className="main-content">
      <div className="scrolldiv relative">
        <div className="heading-selected-generator">
          <h3>3D Mesh</h3>
        </div>

        <div className="page-fluid">
          <Spin spinning={loading}>
            <div className="inline-form">
              <Form name="basic" onFinish={onFinish}>
                <Row gutter={16}>
                  <Col className="gutter-row" span={8}>
                    <Form.Item
                      label="Classes"
                      name="classes"
                      rules={[
                        {
                          required: true,
                          message: "Please input your class!",
                        },
                      ]}
                    >
                      <Select
                        labelInValue
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Class"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={[
                          {
                            value: "airplane,0",
                            label: "airplane",
                          },
                          {
                            value: "aeroplane,0",
                            label: "aeroplane",
                          },
                          {
                            value: "plane,0",
                            label: "plane",
                          },
                          {
                            value: "ashcan,1",
                            label: "ashcan",
                          },
                          {
                            value: "trash,1",
                            label: "trash",
                          },
                          {
                            value: "can,1",
                            label: "can",
                          },
                          {
                            value: "garbage,1",
                            label: "garbage",
                          },
                          {
                            value: "wastebin,1",
                            label: "wastebin",
                          },
                          {
                            value: "ash,1",
                            label: "ash",
                          },
                          {
                            value: "bin,1",
                            label: "bin",
                          },
                          {
                            value: "ash-bin,1",
                            label: "ash-bin",
                          },
                          {
                            value: "ashbin,1",
                            label: "ashbin",
                          },
                          {
                            value: "dustbin,1",
                            label: "dustbin",
                          },
                          {
                            value: "barrel,1",
                            label: "barrel",
                          },
                          {
                            value: "bag,2",
                            label: "bag",
                          },
                          {
                            value: "traveling,2",
                            label: "traveling",
                          },
                          {
                            value: "travelling,2",
                            label: "travelling",
                          },
                          {
                            value: "grip,2",
                            label: "grip",
                          },
                          {
                            value: "suitcase,2",
                            label: "suitcase",
                          },
                          {
                            value: "basket,3",
                            label: "basket",
                          },
                          {
                            value: "handbasket,3",
                            label: "handbasket",
                          },
                          {
                            value: "bathtub,4",
                            label: "bathtub",
                          },
                          {
                            value: "bathing,4",
                            label: "bathing",
                          },
                          {
                            value: "tub,4",
                            label: "tub",
                          },
                          {
                            value: "bath,4",
                            label: "bath",
                          },
                          {
                            value: "bed,5",
                            label: "bed",
                          },
                          {
                            value: "bench,6",
                            label: "bench",
                          },
                          {
                            value: "birdhouse,7",
                            label: "birdhouse",
                          },
                          {
                            value: "bookshelf,8",
                            label: "bookshelf",
                          },
                          {
                            value: "bottle,9",
                            label: "bottle",
                          },
                          {
                            value: "bowl,10",
                            label: "bowl",
                          },
                          {
                            value: "bus,11",
                            label: "bus",
                          },
                          {
                            value: "autobus,11",
                            label: "autobus",
                          },
                          {
                            value: "coach,11",
                            label: "coach",
                          },
                          {
                            value: "charabanc,11",
                            label: "charabanc",
                          },
                          {
                            value: "double-decker,11",
                            label: "double-decker",
                          },
                          {
                            value: "jitney,11",
                            label: "jitney",
                          },
                          {
                            value: "motorbus,11",
                            label: "motorbus",
                          },
                          {
                            value: "motorcoach,11",
                            label: "motorcoach",
                          },
                          {
                            value: "omnibus,11",
                            label: "omnibus",
                          },
                          {
                            value: "passenger,11",
                            label: "passenger",
                          },
                          {
                            value: "vehi,11",
                            label: "vehi",
                          },
                          {
                            value: "cabinet,12",
                            label: "cabinet",
                          },
                          {
                            value: "camera,13",
                            label: "camera",
                          },
                          {
                            value: "photographic,13",
                            label: "photographic",
                          },
                          {
                            value: "tin,14",
                            label: "tin",
                          },
                          {
                            value: "cap,15",
                            label: "cap",
                          },
                          {
                            value: "car,16",
                            label: "car",
                          },
                          {
                            value: "auto,16",
                            label: "auto",
                          },
                          {
                            value: "automobile,16",
                            label: "automobile",
                          },
                          {
                            value: "machine,16",
                            label: "machine",
                          },
                          {
                            value: "motorcar,16",
                            label: "motorcar",
                          },
                          {
                            value: "cellular,17",
                            label: "cellular",
                          },
                          {
                            value: "telephone,17",
                            label: "telephone",
                          },
                          {
                            value: "phone,17",
                            label: "phone",
                          },
                          {
                            value: "cellphone,17",
                            label: "cellphone",
                          },
                          {
                            value: "cell,17",
                            label: "cell",
                          },
                          {
                            value: "mobile phone,17",
                            label: "mobile phone",
                          },
                          {
                            value: "chair,18",
                            label: "chair",
                          },
                          {
                            value: "clock,19",
                            label: "clock",
                          },
                          {
                            value: "computer,20",
                            label: "computer",
                          },
                          {
                            value: "keyboard,20",
                            label: "keyboard",
                          },
                          {
                            value: "keypad,20",
                            label: "keypad",
                          },
                          {
                            value: "dishwasher,21",
                            label: "dishwasher",
                          },
                          {
                            value: "dish,21",
                            label: "dish",
                          },
                          {
                            value: "washer,21",
                            label: "washer",
                          },
                          {
                            value: "dishwashing,21",
                            label: "dishwashing",
                          },
                          {
                            value: "display,22",
                            label: "display",
                          },
                          {
                            value: "video,22",
                            label: "video",
                          },
                          {
                            value: "earphone,23",
                            label: "earphone",
                          },
                          {
                            value: "earpiece,23",
                            label: "earpiece",
                          },
                          {
                            value: "headphone,23",
                            label: "headphone",
                          },
                          {
                            value: "faucet,24",
                            label: "faucet",
                          },
                          {
                            value: "spigot,24",
                            label: "spigot",
                          },
                          {
                            value: "file,25",
                            label: "file",
                          },
                          {
                            value: "cabinet,25",
                            label: "cabinet",
                          },
                          {
                            value: "filing,25",
                            label: "filing",
                          },
                          {
                            value: "guitar,26",
                            label: "guitar",
                          },
                          {
                            value: "helmet,27",
                            label: "helmet",
                          },
                          {
                            value: "jar,28",
                            label: "jar",
                          },
                          {
                            value: "knife,29",
                            label: "knife",
                          },
                          {
                            value: "lamp,30",
                            label: "lamp",
                          },
                          {
                            value: "laptop,31",
                            label: "laptop",
                          },
                          {
                            value: "loudspeaker,32",
                            label: "loudspeaker",
                          },
                          {
                            value: "speaker,32",
                            label: "speaker",
                          },
                          {
                            value: "unit,32",
                            label: "unit",
                          },
                          {
                            value: "system,32",
                            label: "system",
                          },
                          {
                            value: "mailbox,33",
                            label: "mailbox",
                          },
                          {
                            value: "letter,33",
                            label: "letter",
                          },
                          {
                            value: "box,33",
                            label: "box",
                          },
                          {
                            value: "microphone,34",
                            label: "microphone",
                          },
                          {
                            value: "mike,34",
                            label: "mike",
                          },
                          {
                            value: "microwave,35",
                            label: "microwave",
                          },
                          {
                            value: "oven,35",
                            label: "oven",
                          },
                          {
                            value: "motorcycle,36",
                            label: "motorcycle",
                          },
                          {
                            value: "bike,36",
                            label: "bike",
                          },
                          {
                            value: "mug,37",
                            label: "mug",
                          },
                          {
                            value: "piano,38",
                            label: "piano",
                          },
                          {
                            value: "pianoforte,38",
                            label: "pianoforte",
                          },
                          {
                            value: "forte-piano,38",
                            label: "forte-piano",
                          },
                          {
                            value: "pillow,39",
                            label: "pillow",
                          },
                          {
                            value: "pistol,40",
                            label: "pistol",
                          },
                          {
                            value: "handgun,40",
                            label: "handgun",
                          },
                          {
                            value: "side,40",
                            label: "side",
                          },
                          {
                            value: "arm,40",
                            label: "arm",
                          },
                          {
                            value: "shooting,40",
                            label: "shooting",
                          },
                          {
                            value: "iron,40",
                            label: "iron",
                          },
                          {
                            value: "pot,41",
                            label: "pot",
                          },
                          {
                            value: "flowerpot,41",
                            label: "flowerpot",
                          },
                          {
                            value: "printer,42",
                            label: "printer",
                          },
                          {
                            value: "printing,42",
                            label: "printing",
                          },
                          {
                            value: "remote,43",
                            label: "remote",
                          },
                          {
                            value: "control,43",
                            label: "control",
                          },
                          {
                            value: "rifle,44",
                            label: "rifle",
                          },
                          {
                            value: "rocket,45",
                            label: "rocket",
                          },
                          {
                            value: "projectile,45",
                            label: "projectile",
                          },
                          {
                            value: "skateboard,46",
                            label: "skateboard",
                          },
                          {
                            value: "sofa,47",
                            label: "sofa",
                          },
                          {
                            value: "couch,47",
                            label: "couch",
                          },
                          {
                            value: "lounge,47",
                            label: "lounge",
                          },
                          {
                            value: "stove,48",
                            label: "stove",
                          },
                          {
                            value: "table,48",
                            label: "table",
                          },
                          {
                            value: "set,50",
                            label: "set",
                          },
                          {
                            value: "tower,51",
                            label: "tower",
                          },
                          {
                            value: "train,52",
                            label: "train",
                          },
                          {
                            value: "railroad,52",
                            label: "railroad",
                          },
                          {
                            value: "vessel,53",
                            label: "vessel",
                          },
                          {
                            value: "watercraft,53",
                            label: "watercraft",
                          },
                          {
                            value: "washer,54",
                            label: "washer",
                          },
                          {
                            value: "automatic,54",
                            label: "automatic",
                          },
                          {
                            value: "washing,54",
                            label: "washing",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row">
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <Row gutter={16}>
              <Col className="gutter-row" span={4}>
                <div className="list-mesh">
                  {meshes && meshes.length > 0 ? (
                    <ul>
                      {meshes.map((item, key) => {
                        return (
                          <li
                            key={key}
                            className={
                              item.id === active ? "active" : "inactive"
                            }
                          >
                            <span className="mesh-name">{item.name}</span>

                            <Tag
                              color={
                                item.status === "Completed" ? "green" : "red"
                              }
                            >
                              {item.status}
                            </Tag>
                            <div className="mesh-actions">
                              <EyeOutlined
                                onClick={() => viewSingleMesh(item.id)}
                              />

                              <Popconfirm
                                title="Sure to delete?"
                                onConfirm={() => deleteSingleMesh(item.id)}
                              >
                                <DeleteOutlined />
                              </Popconfirm>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col className="gutter-row" span={20}>
                {singleMeshes && singleMeshes.length > 0 ? (
                  <div className="output-mesh">
                    {singleMeshes.map((item, key) => {
                      return (
                        <OBJModel
                          key={key}
                          scale={{ x: 0.25, y: 0.25, z: 0.25 }}
                          src={item.mesh_url}
                          width="600"
                          height="600"
                        />
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Spin>
        </div>
      </div>
    </main>
  );
};

export default Mesh;
