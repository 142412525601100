import { BrowserRouter, Routes } from "react-router-dom";

import React, { useState, useEffect } from "react";
import Home from "../pages/home";
import Generate from "../pages/generate";
import { Route } from "react-router";
import Switch from "react-router";
import Login from "../components/Login";
import ForgotPassword from "../components/ForgotPassword";
import Register from "../components/Register";
import Uploader from "../components/Uploader";
import Unauthorized from "../components/Unauthorized";
import RequireAuth from "../components/RequireAuth";
import ProtectedRoute from "./ProtectedRoute";
import CollectionsPreviewPage from "../pages/generator/collection-preview-page";
import TextToImage from "../pages/text-to-image";
// import authToken from "../hook/useAuthToken";
import axios from "axios";
import authToken from "./../hook/useAuthToken";
import API_URL from "./../config/config";
import { useBetween } from "use-between";
import { useSelector, useDispatch } from "react-redux";
import collectionActions from "./../state/actions/collectionActions";
import Mesh from "../pages/mesh";

function Routing() {
  const dispatch = useDispatch();
  const { appToken } = useBetween(authToken);
  const [modelTypes, setModelTypes] = useState(null);
  useEffect(() => {
    loadMedelsTypes();
  }, []);

  const loadMedelsTypes = () => {
    axios({
      method: "GET",
      url: `${API_URL.GAN_IMAGE_MODELS}model_types/`,
    }).then((res) => {
      dispatch(collectionActions.resetModelTypes(res.data.model_types));
      dispatch(
        collectionActions.resetModelTypesNames(res.data.model_types_names)
      );
      setModelTypes(res.data.model_types);
    });
  };
  return (
    <BrowserRouter className="theme2" onLoad={loadMedelsTypes}>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="register" element={<Register />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        <Route
          exact
          path="/"
          element={<ProtectedRoute modelTypesList={modelTypes} />}
        >
          <Route
            path="/uploader"
            element={<Uploader modelTypesList={modelTypes} />}
          />
          <Route
            exact
            path="/"
            element={<Home title="Generators" modelTypesList={modelTypes} />}
          />
          <Route
            exact
            path="/model"
            element={
              <Home
                title="Generators: 'Epicycles'"
                modelTypesList={modelTypes}
              />
            }
          />
          <Route
            exact
            path="/home"
            element={<Home title="Generators" modelTypesList={modelTypes} />}
          />
          <Route
            exact
            path="/generate"
            element={<Generate modelTypesList={modelTypes} />}
          />
          <Route
            exact
            path="/collection-preview-page"
            element={<CollectionsPreviewPage modelTypesList={modelTypes} />}
          />
          <Route exact path="/text-to-image" element={<TextToImage />} />
          <Route exact path="/mesh" element={<Mesh />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Routing;
