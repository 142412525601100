
import React,  { useState} from "react";
import styles from "./../../assets/css/modals.css";
import { RiCloseLine,  } from "react-icons/ri";
import { RiAlertLine } from "react-icons/ri";
import { BiMessageAltAdd } from "react-icons/bi";
import useGeneratorState from "../../hook/useGeneratorState";
import { useNavigate } from "react-router-dom";
import useUpdateCollectionDrawer from '../../hook/useUpdateCollectionDrawer';
import { useBetween } from "use-between";
import { useSelector, useDispatch } from "react-redux";
import settingAction from "./../../state/actions/settingActions";

const GeneratorModal = ({ setIsOpen, type }) => { 
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [generatorName, setGeneratorName] = useState("");
  const {creatNewGenerator} = useGeneratorState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const { showDrawer, setShowDrawer, toggleDrawer, setStateObj } = useBetween(useUpdateCollectionDrawer);

  const createGenerator = (generatorName) => {
    setIsError(false);      
    if( generatorName == undefined || generatorName == null || generatorName == '' )  {
      setIsError(true);        
      setErrorMessage('Please enter generator name.');
      return;
    }
    creatNewGenerator(generatorName, (res) =>{
      if(res.status == undefined || res.status === 200 || res.status === 201){        
        setIsError(false); 
        setErrorMessage('');        
        dispatch(settingAction.toggleModifyCollectionDrawer(true));
        dispatch(settingAction.setCollectionDrawerObject(res));
        setStateObj(res);
        setIsOpen(false);

        // navigation("/uploader", {state: res});        
      } else {
        setIsError(true);        
        setErrorMessage(res.data.message);
      }
    });
  }

  const resetForm = () => {
    setGeneratorName('');
  }
  return (
    <div className="iconontop">
    <div >{styles.darkBG}</div>
      <div className="darkBG" onClick={() => setIsOpen(false)} />
      <div className="centered">
        <div className="modal normal iconontop">
        <div className={[type, "iconTop" , "padding12"].join(' ')}><BiMessageAltAdd style={{ marginBottom: "-3px" }} /></div>
          <div className="modalHeader">
            <h5 className="modal-heading">Create Generator</h5>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
          {isError != '' && (
                  <div
                    data-show="true"
                    className="ant-alert ant-alert-error ant-alert-with-description ant-alert-no-icon error-bar"
                    role="alert"
                  >
                    <div className="ant-alert-content">
                      <div className="ant-alert-description">
                       <b>Error: </b>{errorMessage}
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        resetForm();
                      }}
                      type="button"
                      className="ant-alert-close-icon"
                      tabindex="0"
                    >
                      <span
                        role="img"
                        aria-label="close"
                        className="anticon anticon-close"
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="close"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                )}

            <input type="text" maxLength="100" placeholder="Enter generator name" id="generator" value={generatorName} onChange={(e) => {
               setGeneratorName(e.target.value);
            }} />
          </div>
          <div className="modalActions">
            <div className="actionsContainer">
              <button className="deleteBtn" onClick={() => createGenerator(generatorName)}>
                Create
              </button>
              <button
                className="cancelBtn"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default GeneratorModal;