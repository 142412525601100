import React from "react";
import styles from "./../../assets/css/modals.css";
import { RiCloseLine } from "react-icons/ri";
import { CgMoveDown } from 'react-icons/cg';
import MediaGallery from './../MediaGallery';
import CustomScroll from 'react-custom-scroll';


const MediaGalleryModel = ({closefn, selectMedia, selectedMedia}) => {        
  return (
    <>
    <div >{styles.darkBG}</div>
      <div className="darkBG modal-preview-bg" onClick={() => closefn(false)} />
      <div className="centered modal-preview modal-gallery">
        <div className="modal">
          <div className="modalHeader">
            <h5 className="modal-heading">Media Gallery</h5>
          </div>
          <button className="closeBtn" onClick={() => closefn(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent scrolldiv">
            <div className="modal-flex center">  
            <MediaGallery selectMedia={selectMedia} selectedMedia={selectedMedia} /> 
            </div>            
          </div>                    
        </div>
      </div>
    </>
  );
};
 
export default MediaGalleryModel;