import React, { useState, useEffect } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import API_PATH from "../config/config";
import axios from "axios";
import authToken from "./../hook/useAuthToken";
import { useSelector, useDispatch } from "react-redux";
import drawerPreviewAction from "./../state/actions/drawerPreviewActions";

export default function BlendedImageSegmentationCard({ token, media, index }) {
  const selectedObject = useSelector((state) => state.drawerPreview.selected);
  const imageData = media;
  const [blendedData, setBlendedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const drawerPreviewData = useSelector((state) => state.drawerPreview);
  const dispatch = useDispatch();

  const loadDataOnDrawerPreviewAction = (data) => {
    dispatch(drawerPreviewAction.loadDrawerData(data));
  };

  useEffect(() => {}, [drawerPreviewData]);

  useEffect(() => {
    setTimeout(() => {
      if (media) {
        loadBlendData();
      } else {
        setBlendedData(null);
      }
    }, 200);
  }, [media]);

  const loadBlendData = () => {
    setIsLoading(true);
    const data = {
      image_id: media.id,
      limit: 1,
    };
    axios({
      method: "GET",
      url: `${API_PATH.BLEND_OBJECT}?limit=100&image_id=${media.id}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        setBlendedData(res.data);
        setIsLoading(false);
        if (index == 0) {
          loadDataOnDrawerPreviewAction({ imageData, blendedData: res.data });
        }
      })
      .catch((err) => {
        console.log(err);
        //   setShowLoadingSpinner(false);
      });
  };

  // const loadImage = (path, path2) => {
  //     // setSelectedImagePath(path);
  //     // setSelectedImagePathOutPut(path2);
  //     // setShowPreviewModal(true);
  // }
  return (
    <>
      {imageData && (
        <div
          className={[
            selectedObject == imageData.id ? "selected-panel" : "",
            "link ant-card blended-img-component",
          ].join(" ")}
          onClick={() => {
            loadDataOnDrawerPreviewAction({ imageData, blendedData });
          }}
        >
          <div className="alert iconTop">
            <AiOutlineCheck />
          </div>
          <div className="ant-header text-truncate">
            <div className="text  ">Image: {imageData.name}</div>
          </div>
          <div className="ant-content">
            {isLoading && (
              <div className="ant-spin-nested-loading">
                <div>
                  <div
                    className="ant-spin ant-spin-spinning ant-spin-show-text"
                    aria-live="polite"
                    aria-busy="true"
                  >
                    <span className="ant-spin-dot ant-spin-dot-spin">
                      <i className="ant-spin-dot-item"></i>
                      <i className="ant-spin-dot-item"></i>
                      <i className="ant-spin-dot-item"></i>
                      <i className="ant-spin-dot-item"></i>
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div
              className="link"
              style={{
                backgroundImage:
                  "url(" +
                  (imageData.input_thumbnail_url
                    ? imageData.input_thumbnail_url
                    : imageData.input_url) +
                  ")",
              }}
              onClick={() => {
                loadDataOnDrawerPreviewAction({ imageData, blendedData });
              }}
            ></div>
            {/* <div className='convert-icon'><CgMoveDown /></div> */}
            <div className="link bottom-img blended-image-wrapper">
              {blendedData && (
                <div className="count">count:{blendedData.count}</div>
              )}
              {blendedData && blendedData.count > 0 && (
                <div className="blended-image-panel">
                  {blendedData.results.map((item, index) => {
                    return (
                      <>
                        {index < 8 && (
                          <div
                            className="blended_img-icon"
                            style={{
                              backgroundImage:
                                "url(" +
                                (item.blending_image_thumbnail_url
                                  ? item.blending_image_thumbnail_url
                                  : item.blending_image_url) +
                                ")",
                            }}
                          ></div>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
