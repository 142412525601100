import React, { useState, useEffect, useRef } from "react";
import styles from "./../../assets/css/modals.css";
import { RiCloseLine } from "react-icons/ri";
import {
  Stage,
  Layer,
  Star,
  Text,
  Group,
  Rect,
  Image,
  Transformer,
} from "react-konva";
import useImage from "use-image";
import { render } from "react-dom";

const bgValueMeters = {
  id: undefined,
  width: undefined,
  height: undefined,
  rotated: undefined,
};
const overLayValueMeters = {
  id: undefined,
  width: undefined,
  height: undefined,
  rotated: undefined,
  x: 0,
  y : 0
};

function resetImageMeters(obj, type) {
  const newWidth = obj.parent.children[2].children[0].attrs.width ? obj.parent.children[2].children[0].attrs.width : overLayValueMeters.width;
  const newHeight = obj.parent.children[2].children[0].attrs.height ? obj.parent.children[2].children[0].attrs.height : overLayValueMeters.height;
  const transformX = (obj.attrs["x"]) ? obj.attrs["x"] : 0;
  const transformY = (obj.attrs["x"]) ? obj.attrs["y"] : 0;  
  
  if (type === "bgImageBox") {
    bgValueMeters.width   = newWidth;
    bgValueMeters.height = newHeight
    bgValueMeters.rotation = obj.attrs["rotation"];        
  } else {
    overLayValueMeters.width = newWidth;
    overLayValueMeters.height = newHeight;
    overLayValueMeters.x = transformX - 25;
    overLayValueMeters.y = transformY - 25;
    overLayValueMeters.rotation = obj.attrs["rotation"];    
  }
}

class TransformerComponent extends React.Component {
  mainParam = "test";
  state = {
    valueChangedCallback: undefined,
    count: 0,
  };

  componentDidMount() {
    this.checkNode();    
    const { valueChangedCallback } = this.props;
    this.state = {
      valueChangedCallback: this.props.valueChangedCallback
        ? this.props.valueChangedCallback
        : undefined,
    };
  }

  componentDidUpdate() {    
    this.checkNode();
  }

  onTransformStart(e) {
  }

  onTransform(e) {    
  }

  onDragEnd(e) {    
    resetImageMeters(e.target, e.target.children[0].attrs["name"]);
  }
  onTransformEnd(e) {    
    resetImageMeters(e.target, e.target.children[0].attrs["name"]);    
  }
  checkNode() {
    const stage = this.transformer.getStage();
    const { selectedShapeName } = this.props;

    var selectedNode = stage.findOne("." + selectedShapeName);    
    if(selectedShapeName =="bgImageBox") {
      this.transformer.rotateEnabled(false);      
    } else {
      this.transformer.rotateEnabled(true);
    }
    
    if (selectedNode === this.transformer.node()) {
      return;
    }
    if (selectedNode) {
      const type = selectedNode.getType();
      if (type != "Group") {
        selectedNode = selectedNode.findAncestor("Group");
      }
      // attach to another node
      this.transformer.attachTo(selectedNode);
    } else {
      // remove transformer
      this.transformer.detach();
    }

    this.transformer.getLayer().batchDraw();
  }
  render() {
    return (
      <Transformer
        ref={(node) => {
          this.transformer = node;
        }}
        onDragEnd={this.onDragEnd}
        onTransformStart={this.onTransformStart}
        transform={this.onTransform}
        onTransformEnd={this.onTransformEnd}
      />
    );
  }
}

const ImageEditorModal = ({ closefn, imageList, updateBlendingValues }) => {
  const bgImageRef = useRef();
  const overLayRef = useRef();
  const [imagebg, setImagePathBG] = useImage(imageList[0].obj.blending_image_url);
  const [image, setImagePath] = useImage(imageList[1].obj.blending_image_url);
  const [xAxis, setxAxis] = useState(20);
  const [yAxis, setyAxis] = useState(20);
  const [selectedImageName, setSelectedImageName] = useState("");
  const [showTool, setShowTool] = useState(false);
  const [overLayImageWidth, setOverLayImageWidth] = useState(100);
  const [overLayImageHeight, setOverLayImageHeight] = useState(100);
  const [bgImageHeight, setBgImageHeight] = useState(100);
  const [bgImageWidth, setBgImageWidth] = useState(100);
  const [bgImagexAxis, setBgImagexAxis] = useState(100);
  const [bgImageyAxis, setBgImageyAxis] = useState(100);

  const [overLayImagexAxis, setOverLayImagexAxis] = useState(100);
  const [overLayImageyAxis, setOverLayImageyAxis] = useState(100);


  const saveImageData = (data) => {    
    updateBlendingValues({bg: bgValueMeters, overlay : overLayValueMeters});    
  }

  const calculateAspectRatioFit = (
    srcWidth,
    srcHeight,
    maxWidth,
    maxHeight
  ) => {
    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);    
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  };

  useEffect(() => {    
    overLayValueMeters.id = imageList[1].id;
    
    bgValueMeters.id = imageList[0].id;
    const bgVal = calculateAspectRatioFit(
      imageList[0].originalWidth,
      imageList[0].originalHeight,
      window.innerWidth - 300,
      window.innerHeight - 300
    );
    
    bgValueMeters.width = bgVal.width;
    bgValueMeters.height = bgVal.height;

    setBgImageWidth(bgVal.width);
    setBgImageHeight(bgVal.height);
    setBgImagexAxis((540 - bgVal.width) / 2);
    setBgImageyAxis((190 - bgVal.height) / 2);

    const overlayVal = calculateAspectRatioFit(
      imageList[1].originalWidth,
      imageList[1].originalHeight,
      300,
      110
    );
    setOverLayImageHeight(overlayVal.height);
    setOverLayImagexAxis(overlayVal.width);
    setOverLayImagexAxis((bgImageWidth - overlayVal.width) / 2);
    setOverLayImageyAxis((bgImageHeight - overlayVal.height) / 2);
    overLayValueMeters.width = overlayVal.width;
    overLayValueMeters.height = overlayVal.width;
    overLayValueMeters.x = overLayImagexAxis;
    overLayValueMeters.y = overLayImageyAxis; 

    setShowTool(true);
  });

  overLayValueMeters.id = imageList[1].obj.id;
  bgValueMeters.id = imageList[0].obj.id;

  const valueChangedCallback = (obj) => {    
  };

  const handleStageMouseDown = (e) => {
    // clicked on stage - cler selection
    if (e.target === e.target.getStage()) {
      setSelectedImageName("");
      return;
    }
    // clicked on transformer - do nothing
    const clickedOnTransformer =
      e.target.getParent().className === "Transformer";
    if (clickedOnTransformer) {
      return;
    }

    // find clicked rect by its name
    const name = e.target.name();
    // const rect = this.state.rectangles.find(r => r.name === name);
    if (name) {
      setSelectedImageName(name);
    } else {
      setSelectedImageName("");
    }
  };
  return (
    <div>
      {showTool && (
        <div>
          <div>{styles.darkBG}</div>
          <div
            className="darkBG modal-preview-bg"
            onClick={() => closefn(false)}
          />
          <div className="centered modal-preview image-editor-modal">
            <div className="modal">
              <div className="modalHeader">
              <div className="modal-top-action-btn ">
                <button onClick={() => {
                    saveImageData();
                  }}>Save Blending</button>
                </div>
                <h5 className="modal-heading">Image Editor</h5>
              </div>
              <button className="closeBtn" onClick={() => closefn(false)}>
                <RiCloseLine style={{ marginBottom: "-3px" }} />
              </button>
              <div className="modalContent">
                <div className="modal center bgImageDiv" style={{ height : bgImageHeight, width : bgImageWidth , margin : 'auto' }}>
                  <Stage
                    className="overLayImage"
                    width={bgImageWidth}
                    height={bgImageHeight}
                    onMouseDown={handleStageMouseDown}
                  >
                    <Layer>
                      <Group
                        name="group"
                        x={-20}
                        y={-20}
                        width={bgImageWidth}
                        height={bgImageHeight}
                        fill="red"
                        draggable
                        rotateEnabled="false"                  
                      >
                        <Image
                          ref={overLayRef}
                          // dragable
                          width={bgImageWidth - 20}
                          height={bgImageHeight - 20}
                          name="bgImageBox"
                          x={50}
                          y={50}
                          image={imagebg}
                        />
                      </Group>
                      <Group
                        name="group"
                        x={overLayImagexAxis}
                        y={overLayImageyAxis}
                        width={80}
                        height={80}
                        fill="red"
                        draggable
                      >
                        <Image
                          ref={overLayRef}
                          width={overLayImageWidth}
                          height={overLayImageHeight}
                          name="overLayImageBox"
                          image={image}
                        />
                      </Group>
                      <TransformerComponent
                        selectedShapeName={selectedImageName}
                        valueChangedCallback={valueChangedCallback}
                      />
                    </Layer>
                  </Stage>
                  {/* <div id="overLayImage" className="overLayImage"> <img src={overLayImage} /></div> */}
                </div>                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageEditorModal;
