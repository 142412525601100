import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./../assets/css/template.css";
import API_PATH from "./../config/config";
const LOGIN_URL = `${API_PATH.USER_MANAGEMENT}/login/`;

const Login = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const homePageUrl = "/";
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const listName = process.env.usertable ? process.env.usertable : "users";

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var loginApi = `${LOGIN_URL}`;
      const data = {
        email: user,
        password: pwd,
      };

      const res = fetch(loginApi, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson?.type);
          console.log(responseJson.type);
          if (responseJson.type && responseJson.type === "errors") {
            if (responseJson.message && responseJson.message != "") {
              const msgError = JSON.stringify(responseJson.message);
              setErrMsg(JSON.parse(msgError)[0]);
            } else {
              setErrMsg("There is issue in service.");
            }
          } else {
            setUser("");
            setPwd("");
            const data = responseJson?.data;
            localStorage.setItem("processingToken", JSON.stringify(data));
            // window.locatio = "/";
            navigate("/", { replace: true });
          }
        })
        .catch((error) => {
          setUser("");
          setPwd("");
          console.error(error);
          setErrMsg("Login Failed");
        });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <div className="formPage">
      <div className="bgPage"></div>
      <section className="page section sectiontest tm-template">
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <h1>Sign In</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="username">Email:</label>
          <input
            type="text"
            id="username"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setUser(e.target.value)}
            value={user}
            required
          />

          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
            required
          />
          <button className="button-submit">Sign In</button>
        </form>
        <p>
          Need an Account?
          <br />
          <span className="line action-buttons">
            <Link to="/register">Sign Up</Link>
            <Link to="/forgotPassword" className="right">
              Forgot Password
            </Link>
          </span>
        </p>
      </section>
    </div>
  );
};

export default Login;
